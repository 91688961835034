import { Permission, UserInfo } from "./IUserContext";
import { ICacheService } from "./CacheService";
import { Appl } from "../Appl";
import { ProblemDetails, ValidationError } from "./ErrorService";

export class UserContextBase {

    public _cache: ICacheService = Appl.Cache;
    public userInfoKey = 'UserInfo';

    public async initAsync(): Promise<void> {
        let sessionStarted = this._cache.getCookie<boolean>("userSession");
        if (!sessionStarted) {
            console.log('UserContextBase Init Async - Inside');
            this._cache.removeAllCache();
            this._cache.addOrUpdateCookie<boolean>("userSession", true);
            window.location.reload();
        }
    }

    public get isAuthenticated(): boolean {
        return this.getUser()?.userId !== undefined;
    }

    public get userId(): string | undefined {
        return this.getUser()?.userId;
    }

    public get email(): string | undefined {
        return this.getUser()?.email;
    }

    public get displayName(): string | undefined {
        let displayName = '';
        let user = this.getUser();
        if (user) {
            if (user.displayName) {
                displayName = user.displayName;
            } else if (user.email) {
                displayName = user.email;
            }
        }
        return displayName;
    }

    public get photo(): string {
        return this.getUser()?.photo!;
    }

    public get accessToken(): string | undefined {
        let user = this.getUser();
        if (user) {
            return user.accessToken;
        }
        return undefined;
    }

    public get roles(): Array<string> {
        let roles = this.getUser()?.roles;
        if (!roles) {
            roles = [];
        }
        return roles;
    }

    public get permissions(): Array<Permission> {
        let user = this.getUser();
        if (user) {
            if (user.permissions) {
                return user.permissions;
            } else {
                return [];
            }
        }
        return [];
    }

    public hasRole(roleName: string): boolean | undefined {
        return this.roles?.includes(roleName)
    }


    public hasPermission(permissionName: string): boolean {
        return false;
        //TODO:
        //return this.permissions().includes(permissionName)
    }

    public get hasTokenExpired(): boolean {
        let token = this.getUser();
        if (!token) return true;
        const currentTime = Date.now() / 1000; // Convert to seconds
        //return token.exp < currentTime;
        return false;
    }

    public async saveUserAsync(user?: UserInfo): Promise<void> {
        if (user == null) { return; }
        this._cache.addOrUpdate(this.userInfoKey, JSON.stringify(user))
    }

    public getUser(): UserInfo {
        const user = this._cache.get<UserInfo>(this.userInfoKey) as UserInfo;
        if (user === null) {
            return {
                userId: undefined, username: undefined, email: undefined,
                roles: new Array<string>, displayName: undefined, photo: undefined
            } as UserInfo
        }
        return user;
    }

    public getFetchProblemDetails(error: any): ProblemDetails {
        let pb = new ProblemDetails();
        pb.detail = error.nessage;
        pb.title = 'Network Error'
        let val = new ValidationError();
        val.detail = error.message;
        val.pointer = "";
        pb.errors = [val];
        return pb;
    }
}