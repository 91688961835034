import { Service } from 'typedi';
import { BaseService, IBaseService, SearchRequest, ServerResponse } from '../../../core/services/BaseService';
import { ChangeSubmissionStatusModel } from './submission/list/SubmissionListModel';
import { AccruedSickLeaveModel, BeneficiaryModel, DirectDepositModel, FederalTaxWithHoldingModel, OptionSelectionModel, PersonalInformationModel, ScreaMembershipModel, ServiceRetirementSignOffModel, StateTaxWithHoldingModel, StepStatuses, TemporaryAnnuityModel } from './retiree/memberCreate/ApplyModels';
import { Appl } from '../../../core/Appl';
import { SubmissionModel } from './retiree/addEdit/SubmissionModel';

export interface ISraService extends IBaseService {


  getSubmissionByMemberIdAsync(memberId: string, createIfNotExists: boolean, formTypeId?: number): Promise<ServerResponse>;

  getRetirementAppForAdminAsync(submissionId: number): Promise<ServerResponse>;
  updateRetirementAppForAdminAsync(model: SubmissionModel): Promise<ServerResponse>;

  //TODO:Delete
  // changeSubmissionStatusAsync(model: ChangeSubmissionStatusModel): Promise<ServerResponse>;
  //TODO Delete
  // getSubmissionCommentsAsync(submissionId: number | undefined): Promise<ServerResponse>;

  getAppForRetirementBySubmissionIdAsync(submissionId: number): Promise<ServerResponse>;
  createOrUpdatePersonalInfoAsync(model: PersonalInformationModel): Promise<ServerResponse>;
  createOrUpdateOptionSelectionAsync(model?: OptionSelectionModel): Promise<ServerResponse>;
  createOrUpdateTemporaryAnnuityAsync(model?: TemporaryAnnuityModel): Promise<ServerResponse>;

  getBeneficiaryAsync(submissionId: number): Promise<ServerResponse>;
  createOrUpdateBeneficiaryAsync(model: BeneficiaryModel): Promise<ServerResponse>;
  deleteBeneficiaryAsync(afrBenId: number): Promise<ServerResponse>;
  setMemberAggreedDeclarationStatusAsync(submissionId: number, agree: boolean): Promise<ServerResponse>;

  getTaxWithholdingAsync(submissionId: number | undefined): Promise<ServerResponse>;
  createOrUpdateFederalTaxWithholdingAsync(model: FederalTaxWithHoldingModel): Promise<ServerResponse>;

  createOrUpdateStateTaxWithholdingAsync(model: StateTaxWithHoldingModel): Promise<ServerResponse>;

  setTaxWithholdAggreedDeclarationStatusAsync(submissionId: number, agree: boolean): Promise<ServerResponse>;

  getDirectDepositAsync(submissionId: number): Promise<ServerResponse>;
  createOrUpdateDirectDepositAsync(model: DirectDepositModel): Promise<ServerResponse>;

  // getAccruedSickLeaveAsync(submissionId: number): Promise<ServerResponse>;
  // createOrUpdateAccruedSickLeavesync(model: AccruedSickLeaveModel): Promise<ServerResponse>;

  // getScreaMembershipAsync(submissionId: number): Promise<ServerResponse>;
  // createOrUpdateScreaMembershipAsync(model: ScreaMembershipModel): Promise<ServerResponse>;

  getServiceRetirementSignOffAsync(submissionId: number): Promise<ServerResponse>;
  createOrUpdateServiceRetirementSignOffAsync(submissionId: number, model: Array<ServiceRetirementSignOffModel>): Promise<ServerResponse>;
  getSubmittedDocumentsAsync(submissionId: number): Promise<ServerResponse>;
  deleteDocumentsAsync(id: number): Promise<ServerResponse>;

  submitApplication(submissionId: number, name: string): Promise<ServerResponse>;
  // getListOfValuesAsync(tableName: string | undefined, filter?: string): Promise<ServerResponse>;

  updateSelfReviewStatusAsync(submissionId: number, status: string): Promise<ServerResponse>;
  getSubmissionStepStatus(memberId: string): Promise<ServerResponse>;
  updateSubmissionStepStatus(submissionId: number, model: StepStatuses): Promise<ServerResponse>;

  // getQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class SraService extends BaseService implements ISraService {
  constructor() {
    super(`${Appl.Setting.Services.RetirementServiceUrl}`);
  }

  public async getSubmissionByMemberIdAsync(memberId: string, createIfNotExists: boolean, formTypeId: number = 1): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Submission/SubmissionByMemberId/${memberId}/${createIfNotExists}/${formTypeId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getRetirementAppForAdminAsync(submissionId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/retiree/afrForAdmin/${submissionId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }
  public async updateRetirementAppForAdminAsync(model: SubmissionModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/retiree/UpdateRetirementInfoByAdmin`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  // public async changeSubmissionStatusAsync(model: ChangeSubmissionStatusModel): Promise<ServerResponse> {
  //   try {
  //     const response = await this.client.post(`/retirement/Submission/change-submission-status`, model);
  //     return this.getResponse(response);
  //   } catch (error: unknown) {
  //     return await this.getErrorResponse(error);
  //   }
  // }

  // public async getSubmissionCommentsAsync(submissionId: number | undefined): Promise<ServerResponse> {
  //   try {
  //     const response = await this.client.get(`/retirement/submission/submission-comments/${submissionId}`);
  //     return this.getResponse(response);
  //   } catch (error: unknown) {
  //     return await this.getErrorResponse(error);
  //   }
  // }



  //Apply For Retirement
  public async getAppForRetirementBySubmissionIdAsync(submissionId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Retiree/afr/${submissionId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdatePersonalInfoAsync(model: PersonalInformationModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/CreateOrUpdatePersonalInfo`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateOptionSelectionAsync(model: OptionSelectionModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/CreateOrUpdateOptionSelection`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateTemporaryAnnuityAsync(model: TemporaryAnnuityModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/CreateOrUpdateTemporaryAnnuity`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getBeneficiaryAsync(submissionId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Retiree/Beneficiary/${submissionId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateBeneficiaryAsync(model: BeneficiaryModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/CreateOrUpdateBeneficiary`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async deleteBeneficiaryAsync(afrBenId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.delete(`/retirement/Retiree/deleteBeneficiary/${afrBenId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }
  public async setMemberAggreedDeclarationStatusAsync(submissionId: number, agree: boolean): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/setMemberAggreedDeclarationStatus/${submissionId}/${agree}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getTaxWithholdingAsync(submissionId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Retiree/TaxWithholding/${submissionId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateFederalTaxWithholdingAsync(model: FederalTaxWithHoldingModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/CreateOrUpdateFederalTaxWithHolding`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateStateTaxWithholdingAsync(model: StateTaxWithHoldingModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/CreateOrUpdateStateTaxWithHolding`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async setTaxWithholdAggreedDeclarationStatusAsync(submissionId: number, agree: boolean): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/setTaxWithholdAggreedDeclarationStatus/${submissionId}/${agree}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getDirectDepositAsync(submissionId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Retiree/DirectDeposit/${submissionId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateDirectDepositAsync(model: DirectDepositModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/createOrUpdateDirectDeposit`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  // public async getAccruedSickLeaveAsync(submissionId: number): Promise<ServerResponse> {
  //   try {
  //     const response = await this.client.get(`/retirement/Retiree/AccruedSickLeave/${submissionId}`);
  //     return this.getResponse(response);
  //   } catch (error: unknown) {
  //     return await this.getErrorResponse(error);
  //   }
  // }

  // public async createOrUpdateAccruedSickLeavesync(model: AccruedSickLeaveModel): Promise<ServerResponse> {
  //   try {
  //     const response = await this.client.post(`/retirement/Retiree/CreateOrUpdateAccruredSickLeave`, model);
  //     return this.getResponse(response);
  //   } catch (error: unknown) {
  //     return await this.getErrorResponse(error);
  //   }
  // }

  // public async getScreaMembershipAsync(submissionId: number): Promise<ServerResponse> {
  //   try {
  //     const response = await this.client.get(`/retirement/Retiree/ScreaMembership/${submissionId}`);
  //     return this.getResponse(response);
  //   } catch (error: unknown) {
  //     return await this.getErrorResponse(error);
  //   }
  // }

  // public async createOrUpdateScreaMembershipAsync(model: ScreaMembershipModel): Promise<ServerResponse> {
  //   try {
  //     const response = await this.client.post(`/retirement/Retiree/createOrUpdateScreaMembership`, model);
  //     return this.getResponse(response);
  //   } catch (error: unknown) {
  //     return await this.getErrorResponse(error);
  //   }
  // }

  public async getSubmittedDocumentsAsync(submissionId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Retiree/SubmittedDocuments/${submissionId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async deleteDocumentsAsync(id: number): Promise<ServerResponse> {
    try {
      const response = await this.client.delete(`/retirement/Retiree/deletedocument/${id}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getServiceRetirementSignOffAsync(submissionId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Retiree/ServiceRetirementSignOff/${submissionId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateServiceRetirementSignOffAsync(submissionId: number, model: Array<ServiceRetirementSignOffModel>): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/createOrUpdateServiceRetirementSignOff/${submissionId}`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async submitApplication(submissionId: number, name: string): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/submitApplication/${submissionId}?name=${name}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getApplicationContentAsHtmlAsync(submissionId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Retiree/getAppContentAsHtml/${submissionId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }
  // public async getListOfValuesAsync(tableName: string | undefined, filter?: string): Promise<ServerResponse> {
  //   try {
  //     const response = await this.client.get(`/retirement/ListOfValue/byTableName/${tableName}/${filter}`);
  //     return this.getResponse(response);
  //   } catch (error: unknown) {
  //     return await this.getErrorResponse(error);
  //   }
  // }

  public async updateSelfReviewStatusAsync(submissionId: number, status: string): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/updateSelfReviewStatus/${submissionId}/${status}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getSubmissionStepStatus(memberId: string): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/retirement/Retiree/getSubmissionStepStatus/${memberId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }
  public async updateSubmissionStepStatus(submissionId: number, model: StepStatuses): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/retirement/Retiree/updateSubmissionStepStatus/${submissionId}`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }
  // public async getQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse> {
  //   try {
  //     const response = await this.client.post(`/retirement/submission/download-submission-queue/${format}`, searchModel, { responseType: 'blob' });
  //     return this.getResponse(response);
  //   } catch (error: unknown) {
  //     return await this.getErrorResponse(error);
  //   }
  // }
}
