import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { BaseViewModel } from '../../../../core/services/BaseViewModel';
import { Appl } from '../../../../core/Appl';
import { AuthContext } from '../../../../core/services/AuthContext';
import { AuthProvider } from '../../../../core/services/IUserContext';
import { ServerResponse } from '../../../../core/services/BaseService';

@Service()
export default class LoginViewModel extends BaseViewModel {
  constructor() {
    super();
    makeObservable(this);
  }

  @action public async initAsync() {
    this.pageTitle = 'SCERS - Login';
    Appl.Spinner.show();
    if (Appl.User.isAuthenticated) {
      this.navigate('/sra');
    }
    Appl.Spinner.hide();
  }

  @action public async loginAsync(authProvider: AuthProvider = "AzureAD") {
    let result: ServerResponse;
    result = await AuthContext.instance.signInAsync(authProvider, "", "");
    //Below Code will NOT trigger if we use Inline Azure AD Authentication
    if (result.success) {
      window.location.assign(`/sra`);
    } else {
      Appl.Error.handle(result.data);
    }
  }
}
