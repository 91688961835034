import { Service } from 'typedi';
import { BaseService, IBaseService, SearchRequest, ServerResponse } from '../../../../core/services/BaseService';
import { MemberLookupModel } from './invite/docUpload/MemberLookupModel';
import { ResendUploadFileInvitationModel } from './list/UploadRequestListModel';
import { Appl } from '../../../../core/Appl';

export interface ISecureFileUploadService extends IBaseService {

  sendMemberFileUploadInvitationAsync(model: MemberLookupModel): Promise<ServerResponse>;
  reSendMemberFileUploadInvitationAsync(model: ResendUploadFileInvitationModel): Promise<ServerResponse>;
  updateRequestStatusAsync(requestId: number, requestStatus: string): Promise<ServerResponse>;
  getRequestedFilesAsync(requestId: number): Promise<ServerResponse>;
  createOrUpdateMemberFilesAsync(userId: string | undefined, models: any): Promise<ServerResponse>;
  getUploadQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class SecureFileUploadService extends BaseService implements ISecureFileUploadService {
  constructor() {
    super(`${Appl.Setting.Services.MemberServiceUrl}`);
  }

  public async sendMemberFileUploadInvitationAsync(model: MemberLookupModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/members/SecureFileUpload/sendMemberFileUploadInvitation`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async reSendMemberFileUploadInvitationAsync(model: ResendUploadFileInvitationModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/members/SecureFileUpload/resendMemberFileUploadInvitation`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async updateRequestStatusAsync(requestId: number, requestStatus: string): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/members/SecureFileUpload/updateRequestStatus/${requestId}/${requestStatus}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getRequestedFilesAsync(requestId: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/members/SecureFileUpload/requested-files/${requestId}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateMemberFilesAsync(userId: string | undefined, models: any): Promise<ServerResponse> {
    try {
      const response = await this.uploadAsync(`/members/SecureFileUpload/createOrUpdateMemberFiles/${userId}`, models);
      return response;
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getUploadQueueAsFileAsync(format: string | undefined, searchModel: SearchRequest | undefined): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/members/SecureFileUpload/downloadUploadQueue/${format}`, searchModel, { responseType: 'blob' });
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }
}
