import { Service } from 'typedi';
import { BaseAddEditViewModel } from '../../../../../../core/services/BaseViewModel';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Appl } from '../../../../../../core/Appl';
import { DeathReportModel } from './DeathReportModel';
import { ListItem } from '../../../../../../core/services/BaseService';
import { IDeathService } from '../../DeathService';
import { ILovService } from '../../../../utility/lov/LovService';
import { toServerDate, toLocalDate } from '../../../../../../core/services/Helper';

@Service() // eslint-disable-next-line
export default class DeathReportViewModel extends BaseAddEditViewModel {
    private service: IDeathService;
    private lovService: ILovService;
    @observable public model: DeathReportModel = {
        mbrRelationId: undefined,
        reqPhoneType: '',
        mbrContactPrefId: undefined,
        reqAddressLine1: '',
        reqCity: '',
        reqState: '',
        reqZipCode: undefined
    };
    @observable public phoneTypeList: Array<ListItem> = [];
    @observable public contactPrefList: Array<ListItem> = [];
    @observable public memberRelationList: Array<ListItem> = [];
    @observable public submissionStatusList: Array<ListItem> = [];

    @observable public id: number = 0;
    constructor() {
        super('Death Notification Record');
        this.service = Appl.Services.get<IDeathService>('IDeathService');
        this.lovService = Appl.Services.get<ILovService>('ILovService');
        makeObservable(this);
    }

    @action
    public async initAsync(id?: number): Promise<void> {

        if (id && id > 0) {
            this.id = id;
            this.setFormMode("View");
            this.pageTitle = `Death Notification Record #${id}`;
            const result = await this.service?.getByIdAsync(`/members/DeathReporting`, this.id);
            if (result.success) {
                runInAction(() => {
                    this.model = result.data;
                    this.model.mbrBirthDate = toLocalDate(this.model.mbrBirthDate);
                    this.model.mbrDeathDate = toLocalDate(this.model.mbrDeathDate);
                    this.serializeFormData(this.model);
                });
            } else {
                Appl.Error.handle(result.error);
            }
        }

        const phoneResult = await this.lovService.getListOfValuesAsync("phone_type");
        if (phoneResult.success) {
            runInAction(() => {
                this.phoneTypeList = phoneResult.data;
            })
        }
        const contactPrefResult = await this.lovService?.getListOfValuesAsync("scers_member.lov_contact_pref");
        if (contactPrefResult.success) {
            runInAction(() => {
                this.contactPrefList = contactPrefResult.data;
            })
        }

        const relationShipResult = await this.lovService?.getListOfValuesAsync("scers_member.lov_mbr_relation");
        if (relationShipResult.success) {
            runInAction(() => {
                this.memberRelationList = relationShipResult.data;
            })
        }
        const submissionStatusResult = await this.lovService?.getListOfValuesAsync("scers_forms.lov_submission_status");
        if (submissionStatusResult.success) {
            runInAction(() => {
                this.submissionStatusList = submissionStatusResult.data;
            })
        }
    }

    @action
    public async saveAsync(): Promise<void> {
        Appl.Validator.init();
        await Appl.Validator.validateString('First Name', 'mbrFirstName', this.model.mbrFirstName, true, 100);
        await Appl.Validator.validateString('Last Name', 'mbrLastName', this.model.mbrLastName, true, 100);
        await Appl.Validator.validateNumber('SSN', 'mbrSsn', this.model.mbrSsn, true, undefined, undefined, 4, 4);
        await Appl.Validator.validateDate('Date of Birth', 'mbrBirthDate', this.model.mbrBirthDate, true, "mm/dd/yyyy");
        if (this.model.mbrBirthDate! >= new Date()) {
            await Appl.Validator.setError("mbrBirthDate", "Birth Date should not be future date.");
        }
        await Appl.Validator.validateDate('Date of Death', 'mbrDeathDate', this.model.mbrDeathDate, true, "mm/dd/yyyy");
        if (this.model.mbrDeathDate! > new Date()) {
            await Appl.Validator.setError("mbrDeathDate", "Death Date should not be future date.");
        }
        await Appl.Validator.validateString('First Name', 'reqFirstName', this.model.reqFirstName, true, 100);
        await Appl.Validator.validateString('Last Name', 'reqLastName', this.model.reqLastName, true, 100);
        await Appl.Validator.validateString('First Name', 'reqFirstName', this.model.reqFirstName, true, 100);
        await Appl.Validator.validateNumber('Relationship', 'mbrRelationId', this.model.mbrRelationId, true);
        await Appl.Validator.validateEmail('Email Address', 'reqEmail', this.model.reqEmail, true, 100);
        await Appl.Validator.validatePhone('Phone Number', 'reqPhone', this.model.reqPhone, true, 20);
        await Appl.Validator.validateZip('Zip Code', 'reqZipCode', this.model.reqZipCode, false, 5, 5);
        if (Appl.Validator.isValid()) {
            this.model.mbrBirthDate = toServerDate(this.model.mbrBirthDate);
            this.model.mbrDeathDate = toServerDate(this.model.mbrDeathDate)
            const result = await this.service?.createDeathReportAsync(this.model);
            if (result.success) {
                Appl.MessageBox.show(`Your edits have been saved.`, 'Changes Saved Successfully');
                this.setFormMode("View");
            } else {
                Appl.Error.handle(result.error);
            }
        }
    }

    @action
    public onCancel(): void {
        this.model = this.getOriginalFormData();
        this.serializeFormData(this.model);
        this.setFormMode("View")
    }
}
