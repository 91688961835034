import React from 'react';
import { observer } from 'mobx-react';
import { InputErrors } from './InputErrors';
import { Icon } from '../Icon';
import { Appl } from '../../../core/Appl';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./InputCalendar.css";

type CalendarExProps = DatePickerProps & {
  id: string;
  label?: string;
  helpText?: string;
  hidden?: boolean;
  value?: string & Date | undefined | null;
  className?: string;
  suffixid?: string;
  showHint?: boolean,
}

export const InputCalendar: React.FC<CalendarExProps> = observer((props) => {
  const {
    id,
    label,
    helpText,
    hidden,
    value,
    onChange,
    className = 'm-0 flex h-10 w-full rounded-lg border border-neutral-300 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-primary-500 disabled:cursor-not-allowed dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-300',
    suffixid,
    showHint,
    ...rest
  } = props;

  const renderLabel = () => {
    if (label && !hidden) {
      return (
        <label htmlFor={`cal-${id}`} className="font-bold text-15px text-black mb-1 block">
          {label}
          {props.required && <span style={{ color: 'red' }}> * </span>}
          {helpText && <Icon name="question-circle" />}
          {showHint && <span className='font-normal block'>mm/dd/yyyy</span>}
        </label>
      );
    }
    return null;
  };

  return (
    <div>
      {renderLabel()}
      {props.disabled ? (
        <div>{value?.toString()}</div>
      ) : (
        <>
          <DatePicker
            className={className + (props.readOnly && ' text-black !border-neutral-300 !bg-gray-100 !cursor-not-allowed')}
            selected={value}
            data-val-field-label={`${id}-feedback`}
            showIcon={false}
            aria-label={label}
            onChange={onChange as any}
            disabled={props.disabled}
            readOnly={props.readOnly}
            {...rest}
          />
          <InputErrors errors={Appl.Error?.items} viewId={id} suffixid={suffixid} />
        </>
      )}
    </div>
  );
});
