import { useEffect, useRef } from 'react';
import parse from 'html-react-parser';

export default class Helper {

  public static formatPhone(phone: string) {
    var cleaned = ('' + phone).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    if (phone) {
      if (phone.includes('+')) {
        return `${phone}`;
      }
      return `+${phone}`;
    } else {
      return undefined;
    }
  }

  public static formatCurrency(value?: number): string {
    if (value) {
      let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return USDollar.format(value)
    } else {
      return ''
    }

  }
  public static StateChanged: boolean = true;

  public static usePoll(callback: any, delayInSeconds: number) {
    const savedCallback: any = useRef();

    delayInSeconds = delayInSeconds * 1000;

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delayInSeconds !== null) {
        const id = setInterval(tick, delayInSeconds);
        return () => clearInterval(id);
      }
    }, [delayInSeconds]);
  }

  public static printContent(divName: string): void {
    const printContents = window.document.getElementById(divName)!.innerHTML;
    const printWindow = document.open('', '', '');
    if (printWindow !== null) {
      printWindow.document.write(printContents);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      // printWindow.close();
    }
  }


  public static getDayMonthYearString(param?: string): DayMonthYearModel {

    let dayMonthYearModel: DayMonthYearModel = {};
    if (param) {
      let dates = param.split('-');
      dayMonthYearModel.day = Number(dates[2]);
      dayMonthYearModel.month = Number(dates[1]);
      dayMonthYearModel.year = Number(dates[0]);;
    }
    return dayMonthYearModel;
  }

  public static getDateString(year: number, month: number, day: number): string {
    let date = `${year}-${month}-${day}`;
    return date;
  }
  public static twoDigitsFormat(num: number): string {
    if (num) {
      return num.toString().padStart(2, '0');
    } else {
      return "";
    }

  }

  public static roundNumber(value: number, fractionDigits: number): string {
    return value && value != null ? value.toFixed(fractionDigits) : '';
  }

  public static isDateIsNull(param: any): boolean {
    if (param === '') {
      return true;
    } else if (param.toLocaleString().includes('0001')) {
      return true;
    }
    return false;
  }

  public static delay(ms: number) {
    setTimeout(() => { }, ms);
  }

  public static getDays(fromDate: any, toDate: any): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays: any = Math.round(Math.abs((fromDate - toDate) / oneDay));
    return diffDays
  }

  public static getYears(fromDate: any, toDate: any): number {
    let days = this.getDays(fromDate, toDate);
    let year = (days / 365.25)
    return year;
  }

  public static getFormattedAddress(addressLine1: string, addressLine2: string, city: string, state: string, zipCode: string): string {
    let address = '';
    if (addressLine1 && addressLine1 !== "") {
      address += addressLine1
    }
    if (addressLine2 && addressLine2 !== "") {
      address += ` ${addressLine2}`
    }
    if (city && city !== "") {
      if (addressLine1 !== ""
        || addressLine2 !== "") {
        address += `, `
      }
      address += city;
    }
    if (state && state !== "") {
      if (addressLine1 !== ""
        || addressLine2 !== ""
        || city !== "") {
        address += `, `
      }
      address += state;
    }
    if (zipCode && zipCode !== "") {
      address += ` ${zipCode}`
    }
    return address;
  }

  public static formatBytes(bytes: number, decimals = 2): string {
    if (!+bytes) return '0 Bytes'

    const k = 1000;//1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  public static formattedHtml(text: string): any {
    return parse(text);
  }
  public static getFileType(fileName: string) {
    const re = /(?:\.([^.]+))?$/;
    const objects = re.exec(fileName);
    return objects != null ? objects[1] : '';
  }

  public static getContentType(fileType: string) {
    let contentType = '';
    switch (fileType) {
      case 'csv':
        contentType = 'text/csv';
        break;
      case 'json':
        contentType = 'application/json';
        break;
      case 'xml':
        contentType = 'application/xml';
        break;
      case 'docx':
        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'xlsx':
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'csvzip':
      case 'jsonzip':
      case 'zip':
        contentType = 'application/zip';
        break;
      case 'pdf':
        contentType = 'application/pdf';
        break;
      default:
        contentType = 'text/plain';
        break;
    }

    return contentType;
  }

  public static downloadFile(data: any, fileName: string, fileType: string) {
    // Create a blob with the data we want to download as a file
    const contentType = Helper.getContentType(fileType);
    if (fileType === 'json') {
      data = JSON.stringify(data);
    }

    // if(fileType=="pdf"){
    //     data = Helper.base64ToArrayBuffer(data);
    // }
    const blob = new Blob([data], { type: contentType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }
  //   public static base64ToArrayBuffer(base64: any) {
  //     let binaryString = window.atob(base64);
  //     let binaryLen = binaryString.length;
  //     var bytes = new Uint8Array(binaryLen);
  //     for (var i = 0; i < binaryLen; i++) {
  //         var ascii = binaryString.charCodeAt(i);
  //         bytes[i] = ascii;
  //     }
  //     return bytes;
  //   }

  public static getActionBadge(action: string) {
    let color = 'badge ';
    switch (action) {
      case 'Update':
        color += 'bg-warning';
        break;
      case 'New':
        color += 'bg-success';
        break;
      default:
        color += 'bg-secondary';
        break;
    }

    return color;
  }

  public static getDifference(newRecord: any, oldRecord: any, properties: string[]) {
    let content = '<tr><th>VERION</th>';
    let newRow = '<tr><td><b>NEW</b></td>';
    let oldRow = '<tr><td><b>OLD</b></td>';
    properties.forEach(function (property) {
      content += `<th>${property.toUpperCase()}</th>`;
      const bgcolor = (
        typeof newRecord[property] === 'string'
          ? newRecord[property].trim().toUpperCase() != oldRecord[property].trim().toUpperCase()
          : newRecord[property] != oldRecord[property]
      )
        ? 'text-danger'
        : '';
      oldRow += `<td>${oldRecord[property] === null ? '' : oldRecord[property]}</td>`;
      newRow += `<td class="${bgcolor}">${newRecord[property] === null ? '' : newRecord[property]}</td>`;
    });
    return `<table class="table table-striped">${content}</tr>${newRow}</tr>${oldRow}</tr></table>`;
  }
}

//Extension Methods
declare global {
  interface Date {
    toDateOnly(): Date;
  }
  interface String {
    toAddPrefix(prefix: string): string;
  }

  // interface String {
  //     toHyperLink(prefix: string): string;
  // }
}
// Date.prototype.toDateOnly = function (): Date {
//   debugger;
//   return this;
// let date:Date;
//     if(this){
//         try{
//             date = new Date(this.toString().substring(0,10))
//         }catch(e){
//             date = new Date()
//         }

//     }else{
//         date = new Date();
//     }
// return date;
// };

String.prototype.toAddPrefix = function (prefix: string) {
  debugger;
  return `${prefix}${this}`;
};


export interface DayMonthYearModel {
  day?: number;
  month?: number;
  year?: number;
}

//https://dev.to/shubhampatilsd/removing-timezones-from-dates-in-javascript-46ah
export const toServerDate = (date?: Date | null) => {
  if (typeof (date) === "string") {
    return date;
  }
  if (date) {
    const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    const withoutTimezone = new Date(date.valueOf() - tzoffset)
      .toISOString()
      .slice(0, -1);
    return withoutTimezone as string & Date;
  }
  return null;
};

export const toLocalDate = (date?: Date | null) => {
  if (date === null) {
    return null;
  }
  if (!date) {
    return date;
  }
  date = new Date(date)
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date as string & Date
};

export const formatDate = (param: any) => {
  let formatedDateString = '';
  if (param) {
    let stringParam = param.toString();
    if (stringParam.includes('-') && !stringParam.includes('T')) {//Only Date without Time Zone
      var result = stringParam.split('-')
      formatedDateString = `${result[1]}/${result[2]}/${result[0]}`
    } else {
      const date = new Date(param);
      const d = Helper.twoDigitsFormat(date.getDate());
      const m = Helper.twoDigitsFormat(date.getMonth() + 1);
      const y = date.getFullYear();
      return `${m}/${d}/${y}`;
    }
  }
  return formatedDateString;
}

export const formatDateTime = (param: any) => {
  if (param) {
    const date = new Date(param);
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();

    let minutes = Helper.twoDigitsFormat(date.getMinutes());
    if (minutes === "") { minutes = "00" }
    let seconds = Helper.twoDigitsFormat(date.getSeconds());
    if (seconds === "") { seconds = "00" }
    let hour = date.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    const hours = Helper.twoDigitsFormat(hour);
    return `${Helper.twoDigitsFormat(m + 1)}/${Helper.twoDigitsFormat(d)}/${y} ${hours}:${minutes}:${seconds} ${ampm}`;
  }

  return '';
}