import { NavItem } from '../../../../core/services/Models';
import { Appl } from '../../../../core/Appl';
import { SecureFileUploadService } from './SecureFileUploadService';
import MemberLookupViewModel from './invite/docUpload/MemberLookupViewModel';
import UploadRequestListViewModel from './list/UploadRequestListViewModel';

export default class SecureFileUploadModule {

  public static MenuItems: Array<NavItem> = [
    { id: 'members-upload-requests', label: 'Secure File Upload Queue', path: '/members/upload-requests' },
    { id: 'members-invite', label: 'Secure File Upload Request Form', path: '/members/invite/doc-upload' }
  ]

  public static init(): void {
    Appl.Services.add('ISecureFileUploadService', SecureFileUploadService);
    Appl.Services.add('MemberLookupViewModel', MemberLookupViewModel);
    Appl.Services.add('UploadRequestListViewModel', UploadRequestListViewModel);
  }
}
