import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { DataGrid, Button, Icon, SearchToolbar, View, ViewHeader, InputDropdown, Input, InputType, PdfViewer } from '../../../../../core/components/Index';
import { Link, useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { RequireDocumentDetailModel, SubmissionCommentModel, SubmissionListModel } from './SubmissionListModel';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import Helper, { formatDate, formatDateTime } from '../../../../../core/services/Helper';
import SraModule from '../../SraModule';
import { Appl } from '../../../../../core/Appl';
import SubmissionListViewModel from './SubmissionListViewModel';
// import { Button as PrimeButton } from 'primereact/button';
// import { FileInput } from 'lucide-react';
import { DataTableSelectEvent } from 'primereact/datatable';

export const SubmissionListView: React.FC = observer(() => {
  const vm = Appl.Services.get<SubmissionListViewModel>('SubmissionListViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.initAsync();
    await vm.getPagedAsync();
  }, []);

  const retirementEffectiveDateTemplate = (row: SubmissionListModel) => {
    return formatDate(row.retirementEffDate);
  };
  const receivedDateTemplate = (row: SubmissionListModel) => {
    return formatDate(row.received);
  };

  const onRowSelect = (event: DataTableSelectEvent) => {
    let editLink = `/sra/retiree/addEdit/${event.data.submissionId}`;
    if (event.data.submissionStatus !== "Draft") {
      vm.navigate(editLink);
    }
  };

  // const documentList = () => {
  //   const rows: Array<ReactNode> = [];
  //   vm.documentDetailsModel.map((document: RequireDocumentDetailModel, index) => {
  //     rows.push(
  //       <tr key={index + 1}>
  //         <td scope="col">{index + 1}</td>
  //         <td scope="col">{document.afrSupportDocDescr}</td>
  //         <td scope="col">
  //           <span
  //             className="link me-2"
  //             title="View/Download"
  //             onClick={async () => await vm.downloadDocumentAsync(document.afrSupportDocId!, document.afrSupportDocUrl ?? "")}>
  //             {document.afrSupportDocName}
  //           </span>
  //         </td>
  //       </tr>
  //     );
  //   });
  //   return (
  //     <table className="table table-hover table-striped">
  //       <thead>
  //         <tr key={0}>
  //           <th scope="col">#</th>
  //           <th scope="col">Document Type</th>
  //           <th scope="col">File Name</th>
  //         </tr>
  //       </thead>
  //       <tbody>{rows}</tbody>
  //     </table>
  //   );
  // };

  // const commentsList = () => {
  //   const rows: Array<ReactNode> = [];
  //   vm.submissionCommentsModels!.map((comment: SubmissionCommentModel, index) => {
  //     rows.push(
  //       <tr key={index + 1} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
  //         <td scope="col" className='px-3 py-3'>{index + 1}</td>
  //         <td scope="col" className='px-3 py-3'>{comment.afrComment}</td>
  //         {/* <td scope="col" className='px-3 py-3'>{comment.afrCommentBy}</td> */}
  //         <td scope="col" className='px-3 py-3'>{formatDateTime(comment.afrCommentDate)}</td>
  //       </tr>
  //     );
  //   });
  //   return (
  //     <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
  //       <thead className="text-gray-700 uppercase text-sm bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
  //         <tr key={0}>
  //           <th scope="col" className='px-3 py-3'>#</th>
  //           <th scope="col" className='px-3 py-3'>Comment</th>
  //           {/* <th scope="col" className='px-3 py-3'>Comment By</th> */}
  //           <th scope="col" className='px-3 py-3'>Comment Date</th>
  //         </tr>
  //       </thead>
  //       <tbody>{rows}</tbody>
  //     </table>
  //   );
  // };

  // const dialogCommentsFooterTemplate = () => {
  //   return (
  //     <>
  //       <div className="modal-footer">
  //         <Button severity='secondary' title="Close" onClick={() => vm.hideCommentsDialog()} />
  //       </div>
  //     </>
  //   );
  // };

  // const nameTemplate = (row: SubmissionListModel) => {
  //   return <>{row.middleName ? `${row.firstName} ${row.middleName} ${row.lastName}` : `${row.firstName} ${row.lastName}`}</>;
  // };

  // const birthDateTemplate = (row: SubmissionListModel) => {
  //   return formatDate(row.birthDate);
  // };



  // const actionTemplate = (row: SubmissionListModel) => {
  //   let editLink = `/sra/retiree/application/${row.memberId}/getting-started`;
  //   if (row.lovFormTypeId === 2) {
  //     editLink = `/sra/change-of-address/${row.afrApplicationId}`;
  //   } else if (row.lovFormTypeId === 3) {
  //     editLink = `/sra/death-report/${row.afrApplicationId}`;
  //   }
  //   return (
  //     <>
  //       {row.submissionStatus === "Draft" && <></>}
  //       {row.submissionStatus !== "Draft" && <div className="flex" >
  //         <Link to={editLink} title="Review Submission">
  //           <Icon name="file-pen-line" size={24} className="link me-2" title="Review Submission" />
  //         </Link>
  //         <span className="link" onClick={async () => await vm.showSubmissionStatusDialog(row)} title="Change Status">
  //           <Icon name="circle-arrow-out-up-right" size={24} color="black" className="me-2" title="Change Status" />
  //         </span>
  //         {/* <span className="link" onClick={async () => await vm.showCommentsDialog(row.submissionId!)} title="Show Comments">
  //         <Icon name="message-square-quote" size={24} color="black" className="me-2" title="Show Comments" />
  //       </span> */}

  //         {/* {
  //         row.lovFormTypeId === 1 && <span className="link" onClick={async () => await vm.showDocumentListDialog(row)} title='Show Documents'>
  //           <Icon name="book-open-text" size={24} color="black" className="me-2" title="Show Documents" />
  //         </span>
  //       } */}
  //         {
  //           (row.lovFormTypeId === 1 || row.lovFormTypeId === 2) && <span className="link" onClick={async () => await vm.showPrintDialog(row.submissionId!)} title='View/Print'>
  //             <Icon name="printer" size={24} color="black" className="me-2" title="Print" />
  //           </span>
  //         }
  //       </div >
  //       }
  //     </>
  //   );
  // };

  // const dialogFooterTemplate = () => {
  //   return (
  //     <>
  //       <div className="modal-footer">
  //         <Button severity='secondary' className='me-2' title="Cancel" onClick={() => vm.hideChangeSubmissionStatusDialog()} />
  //         <Button
  //           className="btn-primary"
  //           title="OK"
  //           onClick={async () => { await vm.validateChangeStatusAsync(vm.changeStatusModel) && await vm.changeSubmissionStatusAsync() }}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  // const dialogDocumentsFooterTemplate = () => {
  //   return (
  //     <>
  //       <div className="modal-footer">
  //         <Button severity='secondary' title="Close" onClick={() => vm.hideDocumentListDialog()} />
  //       </div>
  //     </>
  //   );
  // };

  // const printApplicationFooterTemplate = () => {
  //   return (
  //     <>
  //       <div className="modal-footer">
  //         <Button title="Close" onClick={() => vm.hidePrintDialog()} />
  //       </div>
  //     </>
  //   );
  // };

  return (
    <>
      <View id="RetireListView" pageTitle={vm.pageTitle} authorized={Appl.User.isAuthenticated}
        showMenuBar={false}
        menuItems={SraModule.MenuItems()}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription}>
          <SearchToolbar
            filterPlaceholder="ID / Name / Phone / Email / PIN"
            filter={vm.searchRequest?.filter}
            onFilterChange={(e) => vm.onInputChange(e, 'filter', vm.searchRequest)}
            onRefresh={async () => await vm.getPagedAsync()}
            addNewVisible={false}
            refreshVisible={false}
            showButton={false}
            // run search on input change
            searchOnInputChange={true}
          />
        </ViewHeader>
        {/* <div className="flex space-x-1">
          <PrimeButton outlined severity="secondary" onClick={async () => await vm.export('csv')}
            className="!border-transparent !bg-transparent">
            <FileInput className="w-4 h-4 mr-1.5" />
            Export
          </PrimeButton>
        </div> */}
        <DataGrid
          pageSize={vm.pageSize}
          dataSource={vm.items}
          totalRowsCount={vm.totalRowsCount}
          searchRequest={vm.searchRequest}
          onRefresh={() => vm.getPagedAsync()}
          selectionMode='single'
          onRowSelect={onRowSelect}
        >
          <Column field="submissionId" header="ID" sortable={true} hidden={false} />
          <Column field="received" header="DATE RECEIVED" sortable={true} body={receivedDateTemplate} />
          <Column field="retirementEffDate" header="RETIREMENT DATE" sortable={true} body={retirementEffectiveDateTemplate} />
          <Column field="firstName" header="FIRST NAME" sortable={true} />
          <Column field="lastName" header="LAST NAME" sortable={true} />
          <Column field="phone" header="PHONE" sortable={true} body={(row: SubmissionListModel) => Helper.formatPhone(row.phone!)} />
          <Column field="email" header="EMAIL" sortable={true} />
          <Column field="pin" header="PIN" sortable={true} />
          <Column field="submissionStatus" header="APPLICATION STATUS" sortable={true} />
        </DataGrid>
        {/* <Dialog
          header="Change Submission Status"
          visible={vm.changeStatusDialogVisible}
          style={{ width: '50vw' }}
          maximizable
          modal
          contentStyle={{ height: '250px' }}
          onHide={() => vm.hideChangeSubmissionStatusDialog()}
          footer={dialogFooterTemplate}
          closable={true}>
          <div className="row mb-3">
            <div className="col-md-12">
              <InputDropdown
                id="submissionStatusId"
                value={vm.changeStatusModel.submissionStatusId}
                label="Submission Status"
                placeholder="Please Select"
                options={vm.submissionStatusListItems}
                optionValue="key"
                optionLabel="value"
                required={true}
                onChange={(e) => vm.onInputChange(e, 'submissionStatusId', vm.changeStatusModel)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Input
                id="afrComment"
                value={vm.changeStatusModel.afrComment}
                inputType={InputType.Text}
                label="Comments"
                placeholder="Comments"
                required={true}
                onChange={(e) => vm.onInputChange(e, 'afrComment', vm.changeStatusModel)}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          header="Documents"
          visible={vm.documentListDialogVisible}
          style={{ width: '70vw' }}
          maximizable
          modal
          contentStyle={{ height: '350px' }}
          onHide={() => vm.hideDocumentListDialog()}
          footer={dialogDocumentsFooterTemplate}
          closable={true}>
          <div className="row mb-3">
            <div className="col-md-12">{documentList()}</div>
          </div>
        </Dialog>
        <Dialog
          header="Comments"
          visible={vm.commentsDialogVisible}
          style={{ width: '70vw' }}
          maximizable
          modal
          contentStyle={{ height: '350px' }}
          onHide={() => vm.hideCommentsDialog()}
          footer={dialogCommentsFooterTemplate}
          closable={true}>
          <div className="row mb-3">
            <div className="col-md-12">{commentsList()}</div>
          </div>
        </Dialog>
        <Dialog
          header="Print Application"
          visible={vm.printDialogVisible}
          maximizable
          maximized={true}
          modal
          onHide={() => vm.hidePrintDialog()}
          footer={printApplicationFooterTemplate}
          closable={true}>
          <PdfViewer data={vm.afrFile} />
        </Dialog> */}
      </View>
    </>
  );
});
