import { Service } from 'typedi';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { NotificationListModel } from './NotificationListModel';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { ListItem } from '../../../../../core/services/BaseService';
import { INotificationService } from '../NotificationService';
import { ILovService } from '../../lov/LovService';
import { toServerDate } from '../../../../../core/services/Helper';

@Service() // eslint-disable-next-line
export default class NotificationListViewModel extends BaseListViewModel<NotificationListModel> {
  private service: INotificationService;
  private lovService: ILovService;
  @observable public lovEventList: Array<ListItem> = [];
  @observable public eventCd: string = '';
  @observable public startDate: Date = new Date();
  @observable public endDate: Date = new Date();
  @observable public statusCode: string = '';

  constructor() {
    super('Notifications List', 'eventNotificationId', false);
    this.service = Appl.Services.get<INotificationService>('INotificationService');
    this.lovService = Appl.Services.get<ILovService>('ILovService');
    makeObservable(this);
  }

  @action
  public async initAsync(): Promise<void> {
    const lovResult = await this.lovService.getListOfValuesAsync('event_notification_code');
    if (lovResult.success) {
      runInAction(() => {
        this.lovEventList = lovResult.data;
      })
    }
  }

  public async getPagedAsync(): Promise<void> {
    let filter = `EventCd=${this.eventCd}|FromDate=${this.startDate ? toServerDate(this.startDate) : ""}|ToDate=${this.endDate ? toServerDate(this.endDate) : ""}`;

    if (this.searchRequest) {
      this.searchRequest.filter = filter;
    }
    const response = await this.service.getPagedAsync(`/utility/notification/search`, this.searchRequest);
    await this.handleGetAllServerResponse(response);
  }

  @action
  public async onSearchChange(e: any): Promise<void> {

    await this.getPagedAsync();
  };

  @action public async showNotificationBodyDialog(row: NotificationListModel): Promise<void> {
    this.selectedRow = row;
    this.dialogVisible = true;
  }
}
