import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { BaseViewModel } from '../../../../core/services/BaseViewModel';
import { ISraService } from '../SraService';
import { Appl } from '../../../../core/Appl';
import { SubmissionModel } from '../retiree/memberCreate/ApplyModels';
import { Role } from '../../../../core/services/Enums';

@Service()
export default class DashboardViewModel extends BaseViewModel {
  @observable public printDialogVisible = false;
  @observable public submission?: SubmissionModel;
  @observable public afrFile?: any;
  private _service: ISraService;
  constructor() {
    super();
    this._service = Appl.Services.get<ISraService>('ISraService');
    makeObservable(this);
  }

  @action public async initAsync(): Promise<void> {

    this.pageTitle = 'My Service Retirement Dashboard';
    if (Appl.User.hasRole(Role.Admin)) {
      this.pageTitle = 'Admin - Service Retirement Dashboard';
    }

    const result = await this._service.getSubmissionByMemberIdAsync(Appl.User.userId!, false);
    if (result.success) {
      this.submission = result.data;
    } else {
      Appl.Error.handle(result.error);
    }
  }

  @action public async showPrintDialog(): Promise<void> {
    const result = await this._service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${this.submission?.submissionId}/6105b`);
    if (result.success) {
      this.afrFile = result.data
    } else {
      Appl.Error.handle(result.error);
    }
    this.printDialogVisible = true;
  }

  @action public async hidePrintDialog(): Promise<void> {
    this.printDialogVisible = false;
  }
}
