import { observer } from 'mobx-react';
import { Button, Input, InputType, View, ViewHeader } from '../../../../../core/components/Index'
import useAsyncEffect from 'use-async-effect';
import { useNavigate, useParams } from 'react-router-dom';
import { Appl } from '../../../../../core/Appl';
import LovViewModel from './LovViewModel';

const LovView = observer(() => {
  const vm = Appl.Services.get<LovViewModel>('LovViewModel');
  vm.navigate = useNavigate();
  let { type, id } = useParams();
  useAsyncEffect(async () => {
    await vm.initAsync(type as string, id as unknown as number);
  }, []);

  return (
    <>
      <View
        id="LovView"
        pageTitle={vm.pageTitle}
        authorized={Appl.User.isAuthenticated}
        showMenuBar={false}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" backUrl="" />
        <div className="space-y-6 mt-4">
          <div className='grid grid-cols-1 gap-4'>
            <div>
              <Input
                id="code"
                value={vm.model?.code}
                label='Code'
                inputType={InputType.Text}
                onChange={(e) => vm.onInputChange(e, 'code', vm.model)}
                maxLength={vm.lovConfig.codeColumnLength}
                style={{ width: '400px' }}
                required={true}
                readOnly={vm.model.id! > 0}
              />
            </div>
            <div>
              <Input
                id="description"
                value={vm.model?.description}
                label='Description'
                inputType={InputType.Multiline}
                onChange={(e) => vm.onInputChange(e, 'description', vm.model)}
                maxLength={vm.lovConfig.descriptionColumnLength}
                style={{ width: '400px' }}
                rows={5}
                required={true}
              />
            </div>
          </div>

          <div className="flex items-center justify-center gap-x-6 my-6">
            <Button title="Save"
              onClick={async () => await vm.saveAsync()} />
            <Button title="Cancel" severity="secondary" onClick={() => { vm.model = {}; vm.navigate(`/lov/${type}`) }} />
          </div>
        </div>
      </View >
    </>
  );
});

export default LovView;
