import React from 'react';
import './DashboardView.css';
import { observer } from 'mobx-react';
import { Button, Icon, PdfViewer, View, ViewHeader } from '../../../../core/components/Index';
import { Link, useNavigate } from 'react-router-dom';
import DashboardViewModel from './DashboardViewModel';
import useAsyncEffect from 'use-async-effect';
import { Dialog } from 'primereact/dialog';
import SraModule from '../SraModule';
import { Appl } from '../../../../core/Appl';
import { Role } from '../../../../core/services/Enums';
import { SubmissionStatus } from '../SraEnums';

export const DashboardView: React.FC = observer(() => {
  const vm = Appl.Services.get<DashboardViewModel>('DashboardViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.initAsync();
  }, []);

  const printApplicationFooterTemplate = () => {
    return (
      <>
        <div className="modal-footer">
          <Button title="Close" severity='info' onClick={() => vm.hidePrintDialog()} />
        </div>
      </>
    );
  };

  return (
    <>
      <View id="DashboardView" pageTitle={vm.pageTitle} authorized={Appl.User.isAuthenticated}
        showMenuBar={false}
        menuItems={SraModule.MenuItems()}>
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" />
        <div className="grid grid-cols-3 gap-4">
          <div>
            {Appl.User.hasRole(Role.Admin) && (
              <div className="colour-1 p-6 rounded-xl h-full">
                <div className="content">
                  <h5>
                    <Icon name="file-pen-line" title="" color="white" /> VIEW SUBMISSIONS
                  </h5>
                  <p>View & Approve Retirement Application Forms.</p>
                </div>
                <div className="text-end content-footer">
                  <Link to="/sra/retiree" className="btn btn-primary text-end">
                    View
                  </Link>
                </div>
              </div>
            )}
            {Appl.User.hasRole(Role.Member) && (
              <div className="colour-1 p-6 rounded-xl h-full">
                <>
                  {!vm.submission &&
                    <>
                      <div className="content">
                        <h5>
                          <Icon name="file-pen-line" title="" color="white" />APPLY FOR RETIREMENT
                        </h5>
                        <p>
                          Manage your Retirement Application Form.
                        </p>
                      </div>
                      <div className="text-end content-footer">
                        <Button title="View Submission" onClick={() => vm.navigate(`/sra/retiree/application/${Appl.User.userId}`)} />
                      </div>
                    </>
                  }
                  {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Draft &&
                    <>
                      <div className="content">
                        <h5>
                          <Icon name="file-pen-line" title="" color="white" />SUBMISSION IS DRAFT
                        </h5>
                        <p>
                          Manage your Retirement Application Form.
                        </p>
                      </div>
                      <div className="text-end content-footer">
                        <Button title="View Submission" onClick={() => vm.navigate(`/sra/retiree/application/${Appl.User.userId}`)} />
                      </div>
                    </>
                  }
                  {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Submitted &&
                    <>
                      <div className="content">
                        <h5>
                          <Icon name="check-check" title="" color="white" />APPLICATION SUBMITTED
                        </h5>
                        <p>
                          Congratulations! You have completed submission of your retirement application.
                        </p>
                      </div>
                      <div className="text-end content-footer">
                        <Button title="View / Print" onClick={async () => await vm.showPrintDialog()} />
                      </div>
                    </>
                  }
                  {vm.submission?.lovSubmissionStatusId === SubmissionStatus.InProgress &&
                    <>
                      <div className="content">
                        <h5>
                          <Icon name="cog" title="" color="white" />APPLICATION UNDER REVIEW
                        </h5>
                        <p>
                          We have received your application with reference #<b>{vm.submission?.submissionId}</b>. We are in the process of reviewing your application and will notify you when it is done.
                        </p>
                      </div>
                      <div className="text-end content-footer">
                        <Button title="View / Print" onClick={async () => await vm.showPrintDialog()} />
                      </div>
                    </>
                  }
                  {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Completed &&
                    <>
                      <div className="content">
                        <h5>
                          <Icon name="shield-check" title="" color="white" />APPLICATION SUBMISSION COMPLETED
                        </h5>
                        <p>
                          We have received your application with reference #<b>{vm.submission?.submissionId}</b>. Your application submission process completed.
                        </p>
                      </div>
                      <div className="text-end content-footer">
                        <Button title="View / Print" onClick={async () => await vm.showPrintDialog()} />
                      </div>
                    </>
                  }
                </>
              </div>
            )}
          </div>
          <div >
            <div className="colour-2 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="clipboard-list" title="" color="white" /> RETIREMENT CONSIDERATIONS
                </h5>
                <p>Factors to consider during your Retirement Planning.</p>
              </div>
              <div className="text-end content-footer">
                <Button title="View" onClick={() => vm.navigate(`/sra/retirement-considerations`)} />
              </div>
            </div>
          </div>
          <div>
            <div className="colour-3 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="list-checks" title="" color="white" /> RETIREMENT CHECKLISTS
                </h5>
                <p>View Retirement Planning Checklist.</p>
              </div>
              <div className="text-end content-footer">
                <Button title="View" onClick={() => vm.navigate(`/sra/planning-checklist`)} />
              </div>
            </div>
          </div>
          {Appl.User.hasRole(Role.Member) && <div>
            <div className="colour-4 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="list-checks" title="" color="white" /> CHANGE OF ADDRESS
                </h5>
                <p>Request for Change of Address.</p>
              </div>
              <div className="text-end content-footer">
                <Button title="Request" onClick={() => vm.navigate(`/members/change-of-address/${Appl.User.userId}`)} />
              </div>
            </div>
          </div>
          }
        </div>
        <Dialog
          header="Print Application"
          visible={vm.printDialogVisible}
          maximizable
          maximized={true}
          modal
          onHide={() => vm.hidePrintDialog()}
          footer={printApplicationFooterTemplate}
          closable={true}>
          <PdfViewer data={vm.afrFile} />
        </Dialog>
      </View>
    </>
  );
});
