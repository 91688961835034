import { Service } from 'typedi';
import { BaseService, IBaseService, SearchRequest, ServerResponse } from '../../../../core/services/BaseService';
import { Appl } from '../../../../core/Appl';

export interface INotificationService extends IBaseService {

}

@Service() // eslint-disable-next-line
export class NotificationService extends BaseService implements INotificationService {
    constructor() {
        super(`${Appl.Setting.Services.UtilityServiceUrl}`);
    }
}
