import { Service } from 'typedi';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { LovListModel } from './LovListModel';
import { ILovService } from '../LovService';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { ListItem } from '../../../../../core/services/BaseService';

@Service() // eslint-disable-next-line
export default class LovListViewModel extends BaseListViewModel<LovListModel> {
  private service: ILovService;
  @observable public tableName = 'scers_forms.lov_account_type';
  @observable public lovTableList: Array<ListItem> = [];
  constructor() {
    super('List of Values', 'id', false);
    this.service = Appl.Services.get<ILovService>('ILovService');
    this.searchRequest = { skip: 0, take: 1000, sortColumn: "id", sortAscending: true, filter: '' };
    makeObservable(this);
  }

  @action
  public async initAsync(type?: string): Promise<void> {
    this.tableName = type ? type : "scers_forms.lov_account_type";
    const lovTableResult = await this.service.getLovConfigsAsync();
    if (lovTableResult.success) {
      runInAction(() => {
        this.lovTableList = lovTableResult.data;
      })
    }
  }

  public async getPagedAsync(): Promise<void> {

    // this.searchRequest = { filter: "", skip: 0, take: 500, sortAscending: true, sortColumn: "Id" };
    const response = await this.service.getLovPagedAsync(this.searchRequest, this.tableName);
    await this.handleGetAllServerResponse(response);
  }

  @action
  public async onTableNameChange(e: any): Promise<void> {
    this.tableName = e.target?.value;
    await this.getPagedAsync();
  };
}
