import { action, makeObservable, observable } from 'mobx';
import { SearchRequest, ServerResponse } from './BaseService';
import { NavigateFunction } from 'react-router-dom';
import { Appl } from '../Appl';
import { AuthContext } from './AuthContext';

export class PagedList<T> {
  public recordCount = 0;
  public data: T[] = [];
}

export class BaseViewModel {
  public navigate!: NavigateFunction;
  @observable public pageTitle = '';
  @observable public pageDescription = '';
  @observable public dialogTitle = 'Confirm';
  @observable public dialogVisible = false;
  @observable public dialogParameter?: object;

  @observable public dialogOperation = 'QCL';
  @observable public showConfirmDialog = false;
  @observable public confirmDialogTitle = 'Confirm';
  @observable public confirmDialogMessage = '';
  @observable public confirmDialogOperation = 'CERTIFY';
  @observable public originalFormData = '';
  @observable public changedFormData = '';
  @observable public formDataChanged = false;
  @observable public formMode: 'View' | 'New' | 'Edit' = 'View';

  constructor() {
    makeObservable(this);
  }

  public async closeAsync(e: any): Promise<void> {
    e.preventDefault();
  }

  @action public async showDialog(dialogTitle?: string, dialogParameter?: any): Promise<void> {
    this.dialogVisible = true;
    this.dialogTitle = dialogTitle!;
    this.dialogParameter = dialogParameter;
  }

  @action public async hideDialog(): Promise<void> {
    this.dialogVisible = false;
    this.dialogParameter = undefined;
  }

  @action public async showConfirmDialogAsync() {
    this.showConfirmDialog = true;
  }

  @action public async hideConfirmDialogAsync() {
    this.confirmDialogMessage = '';
    this.showConfirmDialog = false;
  }

  @action onInputChange = (e: any, id: string, model?: object) => {
    if (!model) {
      model = this;
    }
    if (typeof (e) === "string") {//HTML 5 Date Picker
      (model as any)[id] = e;
      this.setFormDataChanged(model);
      return;
    } else if (e instanceof Date) {//React Date Picker
      (model as any)[id] = e;
      this.setFormDataChanged(model);
      return;
    } else if (e === null) {
      (model as any)[id] = undefined;
      this.setFormDataChanged(model);
      return;
    }
    let target = e.target;
    if (!target) {
      target = e.originalEvent.currentTarget;
    }
    if (target.type === 'checkbox') {
      if (typeof target?.value === "boolean") {
        (model as any)[id] = target?.checked;
      } else {
        (model as any)[id] = target?.value;
      }
    } else {
      (model as any)[id] = target?.value;
    }
    this.setFormDataChanged(model);
  };

  @action public serializeFormData(model: any) {
    this.originalFormData = JSON.stringify(model);
  }

  @action public setFormMode(mode: 'View' | 'New' | 'Edit' = 'View'): void {
    this.formMode = mode;
    this.formDataChanged = false;
    Appl.Error.clear();
  }

  public get isReadOnlyForm(): boolean {
    return this.formMode === 'View'
  }

  @action public setFormDataChanged(model: any): void {
    this.changedFormData = JSON.stringify(model);
    this.formDataChanged = this.originalFormData !== this.changedFormData;
  }

  @action public isFormDataChanged(model: any): boolean {
    this.changedFormData = JSON.stringify(model);
    return this.originalFormData !== this.changedFormData;
  }

  @action public getOriginalFormData(): any {
    return JSON.parse(this.originalFormData)
  }

  @action public async showError(message?: string) {
    Appl.AlertBox.showError(message);
  }
  @action public async showWarning(message?: string) {
    Appl.AlertBox.showWarning(message);
  }

  @action public async showSuccess(message?: string) {
    Appl.AlertBox.showSuccess(message);
  }
}

export class BaseAddEditViewModel extends BaseViewModel {
  constructor(pageTitle = '') {
    super();
    this.pageTitle = pageTitle;
  }
}

export class BaseListViewModel<T> extends BaseViewModel {
  @observable items: Array<T> = new Array<T>();
  @observable pageSize: number = Appl.Setting.GridPageSize;
  @observable totalRowsCount = 0;
  @observable selectedRow?: any;
  @observable public searchRequest: SearchRequest | undefined;

  constructor(pageTitle: string, sortColumn = 'id', sortAscending = true, pageDescription: string = pageTitle) {
    super();
    this.pageTitle = pageTitle;
    this.pageDescription = pageDescription;
    this.searchRequest = { skip: 0, take: this.pageSize, sortColumn: sortColumn, sortAscending: sortAscending, filter: '' };
    makeObservable(this);
  }

  //Should be Overriden by Super Class
  @action public async getPagedAsync() {
    //Intentionally Left Blank
  }

  @action public async setActiveRowAsync(row: any) {
    this.selectedRow = row;
  }

  @action private async setRecordTotalCountAsync(count: number) {
    this.totalRowsCount = count;
  }

  @action private async setNoRecordsAsync() {
    this.totalRowsCount = 0;
    this.items = [];
  }

  @action private async setItemsAsync(items: Array<T>) {
    this.items = items;
  }
  @action private async setPageSizeAsync(pageSize: number) {
    this.pageSize = pageSize;
  }
  @action public async handleGetAllServerResponse(response: ServerResponse) {
    if (response.success) {
      if (response.data) {
        const result = response.data;
        await this.setRecordTotalCountAsync(result.recordCount);
        if (this.totalRowsCount > 0) {
          await this.setPageSizeAsync(this.searchRequest?.take!);
          await this.setItemsAsync(result.data);
        } else {
          await this.setNoRecordsAsync();
        }
      } else {
        await this.setNoRecordsAsync();
      }
    } else {
      if (response.error?.status === 401) {
        Appl.ConfirmBox.show('Your session has been expired.', 'Session Expired', '', 'Login', 'Close', async () => await AuthContext.instance.signInAsync("AzureAD", "", ""))
      } else {
        Appl.MessageBox.show(response.error?.detail, response.error?.title);
      }
    }
  }
}

