import { Service } from 'typedi';
import { BaseService, IBaseService, SearchRequest, ServerResponse } from '../../../../core/services/BaseService';
import { Appl } from '../../../../core/Appl';
import { LovModel } from './addEdit/LovModel';

export interface ILovService extends IBaseService {

    getLovPagedAsync(searchModel: SearchRequest | undefined, type: string): Promise<ServerResponse>;
    getLovByIdAsync(id: number, type: string): Promise<ServerResponse>;
    createOrUpdateLovAsync(model: LovModel, type: string): Promise<ServerResponse>;
    deleteLovAsync(id: number, type: string): Promise<ServerResponse>;
    getLovConfigsAsync(): Promise<ServerResponse>;
    getLovConfigByTypeAsync(type: string): Promise<ServerResponse>;
    getListOfValuesAsync(type: string, filter?: string): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class LovService extends BaseService implements ILovService {
    constructor() {
        super(`${Appl.Setting.Services.UtilityServiceUrl}`);
    }

    public async getLovPagedAsync(searchModel: SearchRequest, type: string): Promise<ServerResponse> {
        try {
            const response = await this.client.post(`/utility/ListOfValue/search/${type}`, searchModel);
            return this.getResponse(response);
        } catch (error: unknown) {
            return await this.getErrorResponse(error);
        }
    }

    public async getLovByIdAsync(id: number, type: string): Promise<ServerResponse> {
        try {
            const response = await this.client.get(`/utility/ListOfValue/${type}/${id}`);
            return this.getResponse(response);
        } catch (error: unknown) {
            return await this.getErrorResponse(error);
        }
    }


    public async createOrUpdateLovAsync(model: LovModel, type: string): Promise<ServerResponse> {
        try {
            const response = await this.client.post(`/utility/ListOfValue/createOrUpdateLov/${type}`, model);
            return this.getResponse(response);
        } catch (error: unknown) {
            return await this.getErrorResponse(error);
        }
    }

    public async deleteLovAsync(id: number, type: string): Promise<ServerResponse> {
        try {
            const response = await this.client.delete(`/utility/ListOfValue/${type}/${id}`);
            return this.getResponse(response);
        } catch (error: unknown) {
            return await this.getErrorResponse(error);
        }
    }

    public async getLovConfigsAsync(): Promise<ServerResponse> {
        try {
            const response = await this.client.get(`/utility/ListOfValue/lov-config-list`);
            return this.getResponse(response);
        } catch (error: unknown) {
            return await this.getErrorResponse(error);
        }
    }

    public async getLovConfigByTypeAsync(type: string): Promise<ServerResponse> {
        try {
            const response = await this.client.get(`/utility/ListOfValue/lov-config/${type}`);
            return this.getResponse(response);
        } catch (error: unknown) {
            return await this.getErrorResponse(error);
        }
    }

    public async getListOfValuesAsync(type: string, filter?: string): Promise<ServerResponse> {
        try {
            const response = await this.client.get(`/utility/ListOfValue/byTableName/${type}/${filter}`);
            return this.getResponse(response);
        } catch (error: unknown) {
            return await this.getErrorResponse(error);
        }
    }
}
