import { Appl } from '../Appl';
import { IUserContext } from './IUserContext';
import { UserContextBase } from './UserContextBase';
import { ServerResponse } from './BaseService';

export class UserContextBuiltIn extends UserContextBase implements IUserContext {

  private static _instance: UserContextBuiltIn;
  public static get instance() {
    return this._instance ?? (this._instance = new UserContextBuiltIn());
  }

  public async signInAsync(username?: string, password?: string): Promise<ServerResponse> {
    Appl.Cache.addOrUpdate("AuthProvider", "Custom");
    let authSetting = Appl.Setting.AuthProviders?.find(o => o.Provider === "Custom");
    const request = new Request(authSetting?.AuthorityUrl!, {
      method: "POST",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username: username, password: password }),
    });
    try {
      const authResponse = await fetch(request)
      if (authResponse.ok) {
        let authData = await authResponse?.json();
        await Appl.User.saveUserAsync(authData);
        return {
          success: true,
          data: authData
        };
      } else {
        return {
          success: false,
          // error: new ProblemDetails(){ detail: authResponse.statusText }
          data: authResponse.statusText
        };
      }
    } catch (e: any) {
      let pb = this.getFetchProblemDetails(e);
      return {
        success: false,
        error: this.getFetchProblemDetails(e),
        data: pb.detail
      };
    }
  }

  public async refreshTokenAsync(): Promise<void> {
  }
}

