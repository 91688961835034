import { Service } from 'typedi';
import { action, makeObservable, observable } from 'mobx';
import { ChangeOfAddressModel } from './ChangeOfAddressModel';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { BaseAddEditViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { IChangeOfAddressService } from '../ChangeOfAddressService';
import { toLocalDate, toServerDate } from '../../../../../core/services/Helper';

@Service() // eslint-disable-next-line
export default class ChangeOfAddressViewModel extends BaseAddEditViewModel {
    private service: IChangeOfAddressService;

    @observable public model: ChangeOfAddressModel = {};
    @observable public sameAsMailingAddressSelected = false;
    @observable public enableSubmitButton = false;
    @observable public id: number = 0;
    constructor() {
        super('Change of Address');
        this.service = Appl.Services.get<IChangeOfAddressService>('IChangeOfAddressService');
        makeObservable(this);
    }

    @action
    public async initAsync(id?: number): Promise<void> {
        if (id && id > 0) {
            this.id = id;
            this.pageTitle = `Change of Address Notification #${id}`;
            const result = await this.service?.getByIdAsync(`/members/ChangeOfAddress`, this.id);
            if (result.success) {
                this.model = result.data;
                this.model.changeEffDate = toLocalDate(this.model.changeEffDate);
            } else {
                Appl.Error.handle(result.error);
            }
        }
        this.model.mbrChangeOfAddressId = this.id;
    }

    @action
    public async saveAddressAsync(): Promise<void> {

        Appl.Validator.init();
        this.model.mailingCountry = "USA";
        this.model.homeCountry = "USA";

        await Appl.Validator.validateString('Full Name', 'fullName', this.model.fullName, true, 200);
        await Appl.Validator.validateNumber('SSN', 'ssn', this.model.ssn, true, undefined, undefined, 4, 4);
        await Appl.Validator.validateDate('Effective Date', 'changeEffDate', this.model.changeEffDate, true, "mm/dd/yyyy");
        if (new Date(this.model.changeEffDate!) <= new Date()) {
            await Appl.Validator.setError("changeEffDate", "Effective Date should be future date.");
        }
        await Appl.Validator.validateString('Street Name', 'mailingAddress', this.model.mailingAddress, true, 200);
        await Appl.Validator.validateString('City', 'mailingCity', this.model.mailingCity, true, 100);
        await Appl.Validator.validateString('State', 'mailingState', this.model.mailingState, true, 50);
        await Appl.Validator.validateZip('Zip', 'mailingZip', this.model.mailingZip, true, 5);
        await Appl.Validator.validateString('Country', 'mailingCountry', this.model.mailingCountry, true, 50);

        await Appl.Validator.validateString('Street Name', 'homeAddress', this.model.homeAddress, true, 200);
        await Appl.Validator.validateString('City', 'homeCity', this.model.homeCity, true, 100);
        await Appl.Validator.validateString('State', 'homeState', this.model.homeState, true, 50);
        await Appl.Validator.validateZip('Zip', 'homeZip', this.model.homeZip, true, 5);
        await Appl.Validator.validateString('Country', 'homeCountry', this.model.homeCountry, true, 50);

        if (Appl.Validator.isValid()) {

            this.model.changeEffDate = toServerDate(this.model.changeEffDate);
            const result = await this.service?.createOrUpdateAsync(this.model);
            if (result.success) {
                this.model = {};
                this.sameAsMailingAddressSelected = false;
                this.enableSubmitButton = false;
                if (this.id > 0) {
                    Appl.MessageBox.show('Change of Address updated successfully.', 'Change of Address.');
                    this.navigate(`/members/change-of-address-notifications`)
                } else {
                    Appl.MessageBox.show('Change of Address request is sent successfully.', 'Change of Address.');
                    this.navigate(`/sra`)
                }
            } else {
                Appl.Error.handle(result.error);
            }
        }
    }

    @action
    public onSameAsMailingAddressChange(e: CheckboxChangeEvent) {
        this.sameAsMailingAddressSelected = e.checked!;
        if (this.sameAsMailingAddressSelected) {
            this.model.homeAddress = this.model.mailingAddress;
            this.model.homeCity = this.model.mailingCity;
            this.model.homeState = this.model.mailingState;
            this.model.homeZip = this.model.mailingZip;
            this.model.homeCountry = this.model.mailingCountry;
        }
    };

    @action
    public onAddressInfoChange(e: any, id: string) {
        (this.model as any)[id] = e.target?.value;
        let homeAddressValue = `${this.model.homeAddress}${this.model.homeCity}${this.model.homeState}${this.model.homeZip}${this.model.homeCountry}`;
        let mailingAddressValue = `${this.model.mailingAddress}${this.model.mailingCity}${this.model.mailingState}${this.model.mailingZip}${this.model.mailingCountry}`;
        if (homeAddressValue !== mailingAddressValue) {
            this.sameAsMailingAddressSelected = false;
        } else {
            this.sameAsMailingAddressSelected = true;
        }
    }


}
