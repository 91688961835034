import React from 'react';
import { observer } from 'mobx-react';
import LoginViewModel from './LoginViewModel';
import useAsyncEffect from 'use-async-effect';
import { useNavigate } from 'react-router-dom';
import { Appl } from '../../../../core/Appl';
import { Button, Container, View } from '../../../../core/components/Index';

const LoginView: React.FC = observer(() => {
  const vm = Appl.Services.get<LoginViewModel>('LoginViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.initAsync();
  }, []);

  return (
    <>
      <View id="LoginView" pageTitle={vm.pageTitle} authorized={true} showMenuBar={false} container={false}>
        {!Appl.User.isAuthenticated && <Container>
          <div className="p-10 max-w-lg mx-auto">
            <div className="bg-white rounded-xl mb-4 py-20 px-20 text-center shadow-card border border-gray-25 space-y-8">
              <h1 className="text-3xl font-semibold mb-4">Login to continue</h1>
              <Button title="Login using Microsoft" severity='secondary' onClick={async () => await vm.loginAsync("AzureAD")} />
            </div>
          </div>
        </Container>
        }
      </View >
    </>
  );
});

export default LoginView;
