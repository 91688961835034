import { Service } from 'typedi';
import { BaseService, IBaseService, ServerResponse } from '../../../core/services/BaseService';
import { Appl } from '../../../core/Appl';

export interface IAccountService extends IBaseService {
}

@Service() // eslint-disable-next-line
export class AccountService extends BaseService implements IAccountService {
  constructor() {
    super(`${Appl.Setting.Services.IdentityServiceUrl}`);
  }
}
