export enum SubmissionStatus {
    Draft = 1,
    InProgress = 2,
    Submitted = 3,
    Completed = 4,
}

export enum PersonalStatus {
    Single = 1,
    Married = 2,
    RegistedDomesticPartner = 3,
    Widowed = 4,
    Divorced = 5,
    TerminatedDomesticPartnership = 6
}

export enum BeneficiaryType {
    Spouse = 1,
    RegisteredDomesticPartner = 2,
    MinorDependentChild = 3,
    EntityTrustOrg = 4,
    InsurableInterest = 5,
    Others = 6
}

export enum BeneficiaryPaymentOption {
    UnmodifiedAllowance = 1,
    Option1 = 2,
    Option2 = 3,
    Option3 = 4,
    Option4 = 5,
}

export enum AccountType {
    CheckingAccount = 1,
    SavingsAccount = 2,
}

export enum SupportedDocuments {
    BirthCertificate = 1,
    MarriageRegistrationCertificate = 2,
    SpouseDomesticPartnerSsn = 3,
    CourtEndorsedDomesticRelationsOrder = 4,
    ElectionOfRetirementAllowance = 5,
    SpouseDomesticPartnerOtherBeneficiaryBirthCertificate = 6,
    Other = 7,
    SpouseDeathCertificate = 8,
    VoidedCheck = 9,
    CertifiedBankLetterOrSavingsAccountStatement = 10,
    TemporaryAnnuitiesEstimateFromSocialSecurityOffice = 11
}