import axios, { Axios, AxiosResponse } from 'axios';
import { setupInterceptorsTo } from './ServiceInterceptor';
import 'reflect-metadata';
import { ProblemDetails } from './ErrorService';

export interface SearchRequest {
  skip: number;
  take: number;
  sortColumn: string;
  sortAscending: boolean;
  filter: string;
  downloadType?: string;
}

export interface ServerResponse {
  success: boolean;
  error?: ProblemDetails;
  data?: any;
}

export interface IBaseService {
  getPagedAsync(url: string, searchModel: SearchRequest | undefined): Promise<ServerResponse>;
  getByIdAsync(url: string, id: number): Promise<ServerResponse>;
  createAsync(url: string, model: object): Promise<ServerResponse>;
  updateAsync(url: string, model: object): Promise<ServerResponse>;
  deleteAsync(url: string, id: number): Promise<ServerResponse>;
  uploadAsync(url: string, model: object): Promise<ServerResponse>;
  downloadAsync(url: string): Promise<ServerResponse>;
}

export class BaseService implements IBaseService {
  protected client: Axios;

  constructor(baseUrl: string) {
    const instance = axios.create({ baseURL: baseUrl });
    this.client = instance;
    setupInterceptorsTo(instance);
  }

  public async getPagedAsync(url: string, searchModel: SearchRequest): Promise<ServerResponse> {
    try {
      const response = await this.client.post(url, searchModel);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getByIdAsync(url: string, id: number): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`${url}/${id}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createAsync(url: string, model: object): Promise<ServerResponse> {
    try {
      const response = await this.client.post(url, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async updateAsync(url: string, model: object): Promise<ServerResponse> {
    try {
      const response = await this.client.put(url, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async deleteAsync(url: string, id: number): Promise<ServerResponse> {
    try {
      const response = await this.client.delete(`${url}/${id}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async uploadAsync(url: string, model: object): Promise<ServerResponse> {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    try {
      const response = await this.client.post(url, model, config);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async downloadAsync(url: string): Promise<ServerResponse> {
    try {
      const response = await this.client.get(url, { responseType: 'blob' });
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  protected getResponse(response: AxiosResponse): ServerResponse {
    const success: boolean = response.status === 200 || response.status === 201 || response.status === 204;
    if (success) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return {
        success: false,
        error: response.data,
        data: response.data,
      };
    }
  }

  protected async getErrorResponse(error: any): Promise<ServerResponse> {
    const isJsonBlob = (data1: any) => data1 instanceof Blob && data1.type === "application/json";
    const responseData = isJsonBlob(error.response?.data) ? await (error.response?.data)?.text() : error.response?.data || {};
    const data = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
    let problemDetails = new ProblemDetails();
    if (data) {
      problemDetails = data;
    } else {
      problemDetails.detail = error.message;
    }
    return { success: false, error: problemDetails };
  }
}

export interface ListItem {
  key: any,
  value: string,
  code?: string
}