import { IIdleTimer, PresenceType, useIdleTimer } from 'react-idle-timer';
import { observer } from 'mobx-react';
import Helper from '../../services/Helper';
import { AuthContext } from '../../services/AuthContext';
import { Appl } from '../../Appl';


type IdleTimerProps = {
    idleTimeoutSeconds?: number;
    idlePromptTimeoutSeconds: number;
    idleRedirectUrl: string;
    idleTimeourtExcludeRoutes?: Array<string>;
    disabled?: boolean;
};
export const IdleTimer: React.FC<IdleTimerProps> = observer(({ idleTimeoutSeconds = 60 * 5,
    idlePromptTimeoutSeconds = 60 * 4, idleRedirectUrl = "/login", disabled = false }) => {

    Helper.usePoll(async () => {
        await AuthContext.instance.refreshTokenAsync('AzureAD');
    }, 600);//10 Minutes

    const onPresenceChange = (presence: PresenceType, idleTimer?: IIdleTimer) => {
        const isIdle = presence.type === 'idle';//when user is idle
        // const isActive = presence.type === 'active' && !presence.prompted;//This will be called on any action happing on UI like Mouse Move and other activities
        const isPrompted = presence.type === 'active' && presence.prompted;//Displays a confirmation prompt before calling onIdle
        console.log(`onPresenceChange:: Active: ${!idleTimer?.isPrompted}, Prompted: ${idleTimer?.isPrompted},Idle:${idleTimer?.isIdle}`)
        if (Appl.Setting.IdleTimeourtExcludeRoutes.includes(location.pathname)) return;
        if (isPrompted) {
            let time = Math.ceil(idleTimer?.getRemainingTime()! / 1000);
            let message = msToTime(time);
            //let reminingTimeMessage = `Your session is about to expire in ${message}. You'll be automatically signed out.<br/>Do you want to stay signed in?`;
            let reminingTimeMessage = `Your session is about to expire. You'll be automatically signed out.<br/>Do you want to stay signed in?`;
            Appl.ConfirmBox.show(reminingTimeMessage, 'Your session is about to expire', '', 'Yes', 'No',
                () => { idleTimer?.activate() }, async () => { idleTimer?.reset(); signout() })
        }
        // let interval = setInterval(() => {
        //     if (isPrompted) {
        //         let time = Math.ceil(idleTimer?.getRemainingTime()! / 1000);
        //         let message = msToTime(time);
        //         let reminingTimeMessage = `Your session is about to expire in ${message}. You'll be automatically signed out.<br/>Do you want to stay signed in?`;
        //         Appl.ConfirmBox.show(reminingTimeMessage, 'Your session is about to expire', '', 'Yes', 'No',
        //             () => { idleTimer?.activate() }, async () => { idleTimer?.reset(); await AuthContext.instance.signInAsync("AzureAD", "", "") })
        //     }
        // }, 1000);
        if (isIdle) {
            Appl.ConfirmBox.hide();
            Appl.MessageBox.show('Your session has been expired.', 'Session Expired', '', 'Login');
            idleTimer?.reset();
            signout();
        }
    }
    useIdleTimer({
        timeout: 1000 * idleTimeoutSeconds,//Set the activity timeout, in milliseconds
        promptBeforeIdle: 1000 * idlePromptTimeoutSeconds,//Set the timeout for onPrompt to be called
        disabled: disabled,
        throttle: 500,
        onPresenceChange: (presence: PresenceType, idleTimer?: IIdleTimer) => { onPresenceChange(presence, idleTimer) },
    });
    function signout() {
        Appl.Cache.removeAllCache();
        window.location.href = idleRedirectUrl;
    }
    function msToTime(s: number) {
        function pad(n: number, digits: number = 2) {
            return ('00' + n).slice(-digits);
        }
        const ms = s % 1000;
        s = (s - ms) / 1000;
        const secs = s % 60;
        s = (s - secs) / 60;
        const mins = s % 60;
        const hrs = (s - mins) / 60;
        // return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms);
        if (mins > 0) {
            return `${pad(mins)} minutes and ${pad(secs)} seconds.`;
        } else {
            return `${pad(secs)} seconds.`;
        }
    }

    return (<></>);
});