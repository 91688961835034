import { Service } from 'typedi';
import { BaseAddEditViewModel } from '../../../../../core/services/BaseViewModel';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Appl } from '../../../../../core/Appl';
import { SubmissionDocsModel, SubmissionModel } from './SubmissionModel';
import { ListItem } from '../../../../../core/services/BaseService';
import { ILovService } from '../../../utility/lov/LovService';
import Helper, { toServerDate, toLocalDate } from '../../../../../core/services/Helper';
import { ISraService } from '../../SraService';

@Service() // eslint-disable-next-line
export default class SubmissionViewModel extends BaseAddEditViewModel {


    private service: ISraService;
    private lovService: ILovService;
    @observable public model: SubmissionModel = {};
    @observable public phoneTypeList: Array<ListItem> = [];
    @observable public submissionStatusList: Array<ListItem> = [];

    @observable public submissionId: number = 0;
    @observable public selectAllDocs: boolean = false;

    constructor() {
        super('Online Retirement Application');
        this.service = Appl.Services.get<ISraService>('ISraService');
        this.lovService = Appl.Services.get<ILovService>('ILovService');
        makeObservable(this);
    }

    @action
    public async initAsync(submissionId?: number): Promise<void> {

        if (submissionId && submissionId > 0) {
            this.submissionId = submissionId;
            this.setFormMode("View");
            this.pageTitle = `Online Retirement Application #${submissionId}`;
            const result = await this.service?.getRetirementAppForAdminAsync(this.submissionId);
            if (result.success) {
                runInAction(() => {
                    this.model = result.data;
                    this.model.birthDate = toLocalDate(this.model.birthDate);
                    this.model.retirementEffDate = toLocalDate(this.model.retirementEffDate);
                    this.model.createdDate = toLocalDate(this.model.createdDate);
                    this.serializeFormData(this.model);
                });
            } else {
                Appl.Error.handle(result.error);
            }
        }

        const phoneResult = await this.lovService.getListOfValuesAsync("phone_type");
        if (phoneResult.success) {
            runInAction(() => {
                this.phoneTypeList = phoneResult.data;
            })
        }
        const submissionStatusResult = await this.lovService?.getListOfValuesAsync("scers_forms.lov_submission_status");
        if (submissionStatusResult.success) {
            runInAction(() => {
                this.submissionStatusList = submissionStatusResult.data;
            })
        }
    }

    @action
    public async saveAsync(): Promise<void> {
        Appl.Validator.init();
        await Appl.Validator.validateString('First Name', 'firstName', this.model.firstName, true, 100);
        await Appl.Validator.validateString('Last Name', 'lastName', this.model.lastName, true, 100);
        await Appl.Validator.validateDate('Date of Birth', 'birthDate', this.model.birthDate, true, "mm/dd/yyyy");
        await Appl.Validator.validateNumber('SSN', 'ssn', this.model.ssn, true, undefined, undefined, 4, 4);
        await Appl.Validator.validateEmail('Email Address', 'email', this.model.email, true, 100);
        await Appl.Validator.validatePhone('Phone Number', 'phone', this.model.phone, true, 20);

        await Appl.Validator.validateString('Street Name', 'mailingAddress', this.model.mailingAddress, true, 200);
        await Appl.Validator.validateString('City', 'mailingCity', this.model.mailingCity, true, 100);
        await Appl.Validator.validateString('State', 'mailingState', this.model.mailingState, true, 50);
        await Appl.Validator.validateZip('Zip', 'mailingZip', this.model.mailingZip, true, 5);

        await Appl.Validator.validateString('Street Name', 'homeAddress', this.model.homeAddress, true, 200);
        await Appl.Validator.validateString('City', 'homeCity', this.model.homeCity, true, 100);
        await Appl.Validator.validateString('State', 'homeState', this.model.homeState, true, 50);
        await Appl.Validator.validateZip('Zip', 'homeZip', this.model.homeZip, true, 5);

        if (Appl.Validator.isValid()) {
            this.model.birthDate = toServerDate(this.model.birthDate);
            this.model.retirementEffDate = toServerDate(this.model.retirementEffDate)
            this.model.createdDate = toServerDate(this.model.createdDate)
            const result = await this.service?.updateRetirementAppForAdminAsync(this.model);
            if (result.success) {
                Appl.MessageBox.show(`Your edits have been saved.`, 'Changes Saved Successfully');
                this.setFormMode("View");
            } else {
                Appl.Error.handle(result.error);
            }
        }
    }

    @action
    public onCancel(): void {
        this.model = this.getOriginalFormData();
        this.serializeFormData(this.model);
        this.setFormMode("View")
    }

    @action public async downloadDocumentsAsync(): Promise<void> {
        let docDtlIds = '';
        let docCount = 0;
        let fileName = ``;
        this.model.documents?.map((document: SubmissionDocsModel, index) => {
            if (document.selected) {
                docDtlIds += `${document.docId}|`;
                fileName = document.docFilename!;
                docCount++;
            }
        });
        if (docDtlIds === '') {
            this.showWarning("Please select minimum one document!");
            return;
        }
        if (docCount > 1) {
            fileName = `ORA-${docDtlIds.replaceAll('|', "")}-${new Date().getFullYear()}${(new Date().getMonth() + 1)}${new Date().getDate()}.zip`;
        } else {
            let fileExt = fileName.split('.')[1];
            fileName = `ORA-${docDtlIds.replaceAll('|', "")}-${new Date().getFullYear()}${(new Date().getMonth() + 1)}${new Date().getDate()}.${fileExt}`;
        }
        const result = await this.service?.downloadAsync(`/retirement/retiree/downloadAsZip/${this.submissionId}/${docDtlIds}`);
        if (result.success) {
            Helper.downloadFile(result.data, fileName, '');
        } else {
            Appl.Error.handle(result.error);
        }
    }

    @action public async downloadDocumentAsync(docDtlId: number, fileName: string): Promise<void> {
        const result = await this.service?.downloadAsync(`/retirement/retiree/download/${this.submissionId}/${docDtlId}`);
        if (result.success) {
            let fileExt = fileName.split('.')[1];
            fileName = `ORA-${docDtlId}-${new Date().getFullYear()}${(new Date().getMonth() + 1)}${new Date().getDate()}.${fileExt}}`;
            Helper.downloadFile(result.data, fileName, '');
        } else {
            Appl.Error.handle(result.error);
        }
    }

    @action public async onSelectUnSelectAll(selected: boolean): Promise<void> {
        this.selectAllDocs = selected;
        this.model.documents?.map((document: SubmissionDocsModel, index) => {
            if (document.virsuStatus === "C") {
                document.selected = selected;
            }
        });
    }
}
