import React, { ReactNode } from 'react';
import { SearchRequest } from '../../services/BaseService';
import { DataTable, DataTableSelectEvent, DataTableSelectionSingleChangeEvent, DataTableStateEvent, DataTableValue, DataTableValueArray } from 'primereact/datatable';
import './DataGrid.css';
import { EmptyState } from '../containers/EmptyState';

export interface DataGridProps {
  title?: string;
  description?: string;
  showColumnHeader?: boolean;
  allowColumnResize?: boolean;
  children?: ReactNode;
  onRefresh?: () => void;
  onExport?: (type: string) => void;
  onAddNew?: () => void;
  onFilterChange?: (e: object) => void;
  onRowChange?: (e: object) => void;
  filter?: string;
  filterPlaceholder?: string;
  filterVisible?: boolean;
  addNewVisible?: boolean;
  exportVisible?: boolean;
  newButtonText?: string;
  dataSource?: object;
  pageSize: number;
  totalRowsCount?: number;
  sortColumn?: string;
  searchRequest?: SearchRequest;
  emptyMessage?: ReactNode;
  pageDropdownNumbers?: number[];
  selection?: number[];
  dataKey?: string;
  onSelectionChange?(event: DataTableSelectionSingleChangeEvent<any>): void;
  selectionMode?: 'single' | 'radiobutton' | undefined;
  onRowSelect?(event: DataTableSelectEvent): void;
}

export class DataGrid extends React.Component<DataGridProps, DataTable<DataTableValueArray>> {
  constructor(props: DataGridProps) {
    super(props);
  }

  render() {
    const setting = this.props;

    const onPageClick = (event: DataTableStateEvent) => {
      if (setting.searchRequest) {
        setting.searchRequest.skip = event.page! * event.rows;
        setting.searchRequest.take = event.rows;
        if (setting.onRefresh) {
          setting.onRefresh();
        }
      }
    };

    const onSortClick = (sortColum: string) => {
      let sortAscending = true;
      if (setting.searchRequest) {
        if (sortColum === setting.searchRequest.sortColumn) {
          sortAscending = !setting.searchRequest.sortAscending;
        }
        setting.searchRequest.sortColumn = sortColum;
        setting.searchRequest.sortAscending = sortAscending;
      }
      if (setting.onRefresh) {
        setting.onRefresh();
      }
    };

    const onRowChange = (row: object) => {
      if (setting.onRowChange) {
        setting.onRowChange(row);
      }
    };

    return (
      <>
        <DataTable
          title={setting.title}
          lazy={true}
          showHeaders={setting.showColumnHeader ?? true}
          resizableColumns={setting.allowColumnResize}
          showGridlines={true}
          stripedRows={true}
          columnResizeMode="expand"
          emptyMessage={setting.emptyMessage ?? <EmptyState title="No Records" description="No Records found for Matching Criteria" />}
          paginator={true}
          value={setting.dataSource as DataTableValue[]}
          rows={setting.pageSize}
          rowsPerPageOptions={setting.pageDropdownNumbers ?? [10, 50, 100, 1000]}
          first={setting.searchRequest?.skip}
          totalRecords={setting.totalRowsCount}
          sortField={setting.sortColumn}
          onPage={(e: DataTableStateEvent) => onPageClick(e)}
          onSort={(e: DataTableStateEvent) => onSortClick(e.sortField)}
          paginatorTemplate={'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'}
          currentPageReportTemplate={`Showing {first} to {last} of {totalRecords} records`}
          paginatorLeft={true}
          onSelectionChange={(row: object) => onRowChange(row)}
          dataKey={this.props.dataKey}
          selectionMode={setting.selectionMode}
          onRowSelect={setting.onRowSelect}
        >
          {this.props.children}
        </DataTable>
      </>
    );
  }
}
