import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { ChevronDown } from 'lucide-react';
import { Appl } from '../../Appl';
import { AuthContext } from '../../../core/services/AuthContext';
import { Popover, PopoverContent, PopoverTrigger } from '../../../ui/Popover';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

export interface MenuBarProfileViewProps {
  profilePictureUrl?: string;
  profileTitle?: string | React.ReactNode;
  role?: string;
  profileUrl?: string;
  logoutUrl?: string;
}

const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
};

export const MenuBarProfileView: React.FC<MenuBarProfileViewProps> = observer((props) => {

  return (
    <Popover>
      <PopoverTrigger className='flex items-center hover:bg-black/5 px-3 py-2 rounded-md transition-colors'>
        <span className="text-gray-900 text-sm mr-2 block">{props.profileTitle}</span>
        <ChevronDown className="h-4 w-4 text-gray-500" />
      </PopoverTrigger>

      <PopoverContent
        className="w-[260px]   p-5 will-change-[transform,opacity]  
          data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade 
          data-[state=open]:data-[side=top]:animate-slideDownAndFade
          rounded-xl bg-white shadow-xl shadow-black/5 ring-[0.15px] ring-slate-700/10
          "

        sideOffset={5}
        align="end"
        data-state="open"
      >
        <div className="animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95">
          <div className="flex items-center justify-center flex-col px-2 py-2 mb-4">
            {props.profilePictureUrl ? (
              <img
                src={props.profilePictureUrl}
                alt="Profile"
                className="h-12 w-12 rounded-full object-cover mb-2"
              />
            ) : (
              <div className="h-12 w-12 rounded-full bg-gray-100 flex items-center justify-center text-gray-500 text-xl font-medium mb-2">
                {getInitials(props.profileTitle!.toString())}
              </div>
            )}
            <div className="font-medium text-gray-900 text-center">{props.profileTitle}</div>
            {props.role && <div className="text-sm text-gray-500 text-center">{props.role}</div>}
          </div>

          <Button
            severity='danger' 
            onClick={async () => await AuthContext.instance.signOutAsync("/")}
            className="w-full text-center px-4 py-2.5 text-red-600 hover:bg-red-50 transition-colors !rounded-lg"
          >
            Log out
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
});

export default MenuBarProfileView;