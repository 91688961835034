import React from 'react';
import './HeaderView.css';
import { observer } from 'mobx-react';
import Logo from '../../assets/images/logo_primary.svg';
import { Link } from 'react-router-dom';
import { MenuBarProfileView } from '../../core/components/Index';
import { Appl } from '../../core/Appl';

export const HeaderView: React.FC = observer(() => {
  const userContext = Appl.User;

  return (
    <>
      <header className="fixed top-0 w-full z-10 h-[100px]">
        <nav className={`bg-cream-50 h-full border-b-2 border-orange-500  py-1 p-0 px-4 md:px-6 ${!userContext.isAuthenticated && 'lg:px-12'}`}>
          <div className="flex items-center justify-between">
            <Link to="/" className={`py-2 flex-shrink-0 md:ml-0 ${userContext.isAuthenticated && 'ml-[64px]'}`}>
              <img src={Logo} alt="Logo" className='w-[250px] h-[65px] max-h-[90%] flex-shrink-0' />
            </Link>
            {!window.location.host.toLowerCase().includes('/login') &&
              <div className="flex items-center space-x-4">
                {userContext.isAuthenticated && (
                  <MenuBarProfileView
                    //profilePictureUrl={!props.profilePictureUrl ? `/user/profile/${userContext.user().userId}` : props.profilePictureUrl}
                    profileTitle={userContext.displayName}
                    // role={userContext.roles}
                    logoutUrl={''}
                    profileUrl={'/myProfile'}
                  />
                )}
              </div>
            }

          </div>
        </nav>
      </header>
    </>
  );
});
