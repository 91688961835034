import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { ProfileModel } from './ProfileModel';
import { BaseViewModel } from '../../../../core/services/BaseViewModel';

@Service()
export default class ProfileViewModel extends BaseViewModel {
  @observable public model: ProfileModel = new ProfileModel();
  constructor() {
    super();
    makeObservable(this);
  }

  @action public async loadAsync() {
    this.pageTitle = 'My Profile';
  }
}
