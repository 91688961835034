import { Service } from 'typedi';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { BaseAddEditViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { ILovService } from '../LovService';
import { LovConfigModel, LovModel } from './LovModel';

@Service() // eslint-disable-next-line
export default class LovViewModel extends BaseAddEditViewModel {
    private service: ILovService;

    @observable public model: LovModel = {};
    @observable public lovConfig: LovConfigModel = {};

    @observable public enableSubmitButton = false;
    @observable public id: number = 0;
    @observable public type: string = '';
    constructor() {
        super('List Of Values');
        this.service = Appl.Services.get<ILovService>('ILovService');
        makeObservable(this);
    }

    @action
    public async initAsync(type: string, id?: number,): Promise<void> {
        this.type = type;
        this.model = {};
        this.id = id!;
        const lovTableResult = await this.service.getLovConfigByTypeAsync(this.type);
        if (lovTableResult.success) {
            runInAction(() => {
                this.lovConfig = lovTableResult.data;
            })
        }
        this.pageTitle = `Create New List Of Values - ${this.lovConfig.tableDisplayName}`;
        if (id && id > 0) {
            this.pageTitle = `Edit List Of Values - ${this.lovConfig.tableDisplayName} - #${id}`;
            const result = await this.service?.getLovByIdAsync(this.id, type);
            if (result.success) {
                runInAction(() => {
                    this.model = result.data;
                })

            } else {
                Appl.Error.handle(result.error);
            }
        }
    }

    @action
    public async saveAsync(): Promise<void> {
        Appl.Validator.init();
        await Appl.Validator.validateString('Code', 'code', this.model.code, true, this.lovConfig.codeColumnLength);
        await Appl.Validator.validateString('Description', 'description', this.model.description, true, this.lovConfig.descriptionColumnLength);
        if (Appl.Validator.isValid()) {
            const result = await this.service?.createOrUpdateLovAsync(this.model, this.type);
            if (result.success) {
                this.model = {};
                this.navigate(`/lov/${this.type}`)
            } else {
                Appl.Error.handle(result.error);
            }
        }
    }
}
