import { Appl } from "../Appl";

export enum AllowedCharacters {
  All = '',
  Text = 'text',
  Number = '^[0-9\b]+$',
  Email = '^[w-.]+@([w-]+.)+[w-]{2,4}$',
  Phone = '',
  Date = '',
  Time = '',
  Url = '',
  SSN = '^[0-9\b]+$',
  Zip = '^[0-9\b]+$',
}

export interface IValidator {
  RegExZip: RegExp;
  RegExEmail: RegExp;
  RegExPhone: RegExp;
  RegExDate: RegExp;
  RegExNumber: RegExp;

  init(): void;
  isValid(): boolean;
  setError(propertyName: string, message: string): Promise<void>;
  validateString(displayName: string, propName: string, propValue: string | number | boolean | Date | undefined,
    required: boolean, maxLength?: number, minLength?: number): Promise<boolean>;
  validateNumber(displayName: string, propName: string, propValue: any, required: boolean,
    maxValue?: number, minValue?: number, minLength?: number, maxLength?: number,): Promise<boolean>;
  validateEmail(displayName: string, propName: string, propValue: any, required: boolean,
    maxLength?: number, minLength?: number): Promise<boolean>;
  validatePhone(displayName: string, propName: string, propValue: any, required: boolean,
    maxLength: number, minLength?: number): Promise<boolean>;
  validateZip(displayName: string, propName: string, propValue: any, required: boolean,
    maxLength: number, minLength?: number): Promise<boolean>;
  validateDateOnly(displayName: string, propName: string, year?: number,
    month?: number, day?: number, required?: boolean): Promise<boolean>
  validateDate(displayName: string, propName: string, propValue: any, required: boolean,
    format: string): Promise<boolean>

}

export class Validator implements IValidator {

  private static _instance: Validator;
  public static get instance() {
    return this._instance ?? (this._instance = new Validator());
  }

  // public static errors: Map<string, string[]> = new Map<string, string[]>();
  public RegExZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  public RegExEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  public RegExPhone = /^(1[ -]?)?\d{3}[ -]?\d{3}[ -]?\d{4}$/;
  public RegExDate = /\d{1,2}\/\d{1,2}\/\d{4}/;
  public RegExNumber = /^[0-9\b]+$/;

  public init(): void {
    Appl.Error.clear();
  }

  public isValid(): boolean {
    return Appl.Error.count() === 0;
  }

  public async setError(propertyName: string, message: string): Promise<void> {
    Appl.Error.add(propertyName, message);
  }

  public async validateString(
    displayName: string,
    propName: string,
    propValue: string | number | boolean | Date | undefined,
    required: boolean,
    maxLength?: number,
    minLength?: number,
  ): Promise<boolean> {
    const messages: string[] = [];
    this.validateRequired(displayName, propValue, required, messages);
    this.validateMinLength(displayName, propValue, minLength, messages);
    this.validateMaxLength(displayName, propValue, maxLength, messages);
    if (messages.length > 0) {
      Appl.Error.add(propName, messages[0]);
      return false;
    }
    return true;
  }

  public async validateNumber(
    displayName: string,
    propName: string,
    propValue: any,
    required: boolean,
    maxValue?: number,
    minValue?: number,
    minLength?: number,
    maxLength?: number,
  ): Promise<boolean> {
    const messages: string[] = [];
    this.validateRequired(displayName, propValue, required, messages);

    if (propValue !== null && propValue !== undefined && propValue !== '') {
      if (isNaN(propValue)) {
        messages.push(`${displayName} should be a valid number.`);
      }
      if (!isNaN(propValue)) {
        if (minLength) {
          if (propValue.length < minLength) {
            messages.push(`${displayName} should have a minimum of ${minLength} digits.`);
          }
        }
        if (maxLength) {
          if (propValue.length > maxLength) {
            messages.push(`${displayName} should have a max of ${maxLength} digits.`);
          }
        }
        if (maxValue) {
          if (propValue > maxValue) {
            messages.push(`${displayName} should be less than or equal to ${maxValue}.`);
          }
        }
        if (minValue) {
          if (propValue < minValue) {
            messages.push(`${displayName} should have a minimum of ${minValue}.`);
          }
        }
      }
    }

    if (messages.length > 0) {
      Appl.Error.add(propName, messages[0]);
      return false;
    }
    return true;
  }

  public async validateEmail(
    displayName: string,
    propName: string,
    propValue: any,
    required: boolean,
    maxLength?: number,
    minLength?: number,
  ): Promise<boolean> {
    const messages: string[] = [];
    this.validateRequired(displayName, propValue, required, messages);
    this.validateMinLength(displayName, propValue, minLength, messages);
    this.validateMaxLength(displayName, propValue, maxLength, messages);
    if (propValue !== null && propValue !== undefined && propValue !== '') {
      if (!this.RegExEmail.test(propValue)) {
        messages.push(`${displayName} should be a valid email.`);
      }
    }

    if (messages.length > 0) {
      Appl.Error.add(propName, messages[0]);
      return false;
    }
    return true;
  }

  public async validatePhone(
    displayName: string,
    propName: string,
    propValue: any,
    required: boolean,
    maxLength = 20,
    minLength = 10,
  ): Promise<boolean> {
    const messages: string[] = [];
    this.validateRequired(displayName, propValue, required, messages);
    this.validateMinLength(displayName, propValue, minLength, messages);
    this.validateMaxLength(displayName, propValue, maxLength, messages);
    if (propValue !== null && propValue !== undefined && propValue !== '') {
      if (!this.RegExPhone.test(propValue)) {
        // messages.push(`${displayName} should be a valid phone.`);
      }
    }
    if (messages.length > 0) {
      Appl.Error.add(propName, messages[0]);
      return false;
    }
    return true;
  }

  public async validateZip(
    displayName: string,
    propName: string,
    propValue: any,
    required: boolean,
    maxLength = 5,
    minLength = 5,
  ): Promise<boolean> {
    const messages: string[] = [];
    this.validateRequired(displayName, propValue, required, messages);
    this.validateMinLength(displayName, propValue, minLength, messages);
    this.validateMaxLength(displayName, propValue, maxLength, messages);
    if (propValue) {
      if (!this.RegExZip.test(propValue)) {
        messages.push(`${displayName} should be a valid code.`);
      }
    }
    if (messages.length > 0) {
      Appl.Error.add(propName, messages[0]);
      return false;
    }
    return true;
  }

  public async validateDate(
    displayName: string,
    propName: string,
    propValue: any,
    required: boolean,
    format = 'DD/MM/YYYY',
  ): Promise<boolean> {
    const messages: string[] = [];
    this.validateRequired(displayName, propValue, required, messages);
    if (required && propValue && typeof (propValue) !== "string"
      && propValue.toLocaleString().includes('0001')) {
      messages.push(`${displayName} is required.`);
      Appl.Error.add(propName, messages[0]);
      return false;
    }
    if (propValue !== null && propValue !== undefined && propValue !== '' && typeof (propValue) !== "string") {
      const dateString = propValue.toLocaleDateString();
      if (!this.RegExDate.test(dateString)) {
        messages.push(`${displayName} should be a valid date.`);
      }
    }
    if (messages.length > 0) {
      Appl.Error.add(propName, messages[0]);
      return false;
    }
    return true;
  }

  public async validateDateOnly(
    displayName: string,
    propName: string,
    year?: number,
    month?: number,
    day?: number,
    required?: boolean,
  ): Promise<boolean> {
    const messages: string[] = [];
    if (!year || !month || !day) {
      messages.push(`${displayName} is required.`);
    }
    if (year !== null) {
      if (isNaN(year!)) {
        messages.push(`${displayName} with valid year is required.`);
      } else if (year?.toString().length !== 4) {
        messages.push(`${displayName} with valid year is required.`);
      }
    }
    if (month !== null) {
      if (isNaN(month!)) {
        messages.push(`${displayName} with valid month is required.`);
      } else if (month! > 12 || month! < 1) {
        messages.push(`${displayName} with valid month is required.`);
      }
    }
    if (day !== null) {
      if (isNaN(day!)) {
        messages.push(`${displayName} with valid day is required.`);
      } else if (day! > 31 || day! < 1) {
        messages.push(`${displayName} with valid day is required.`);
      }
    }
    if (messages.length > 0) {
      Appl.Error.add(propName, messages[0]);
      return false;
    }
    return true;
  }

  private validateRequired(displayName: string, propValue: any, required: boolean, messages: string[] = []) {
    if (required) {
      if (propValue === null || propValue === undefined || propValue === '') {
        messages.push(`${displayName} is required.`);
      }
    }
  }

  private validateMinLength(displayName: string, propValue: any, minLength?: number, messages: string[] = []) {
    if (propValue) {
      if (minLength) {
        if (propValue.length < minLength) {
          messages.push(`${displayName} should have a minimum of ${minLength} characters.`);
        }
      }
    }
  }

  private validateMaxLength(displayName: string, propValue: any, maxLength?: number, messages: string[] = []) {
    if (propValue) {
      if (maxLength) {
        if (propValue.length > maxLength) {
          messages.push(`${displayName} should be less than or equal to ${maxLength} character.`);
        }
      }
    }
  }
}
