import React from 'react';
import { observer } from 'mobx-react';
import useAsyncEffect from 'use-async-effect';
import { useLocation, useNavigate } from 'react-router-dom';
import { Appl } from '../../../../core/Appl';
import { Role } from '../../../../core/services/Enums';
import { Container, View } from '../../../../core/components/Index';

const RedirectView: React.FC = observer(() => {
    let navigate = useNavigate();
    const location = useLocation();
    useAsyncEffect(async () => {
        let authCode = location.hash;
        let auth = await Appl.User.isAuthenticated;
        if (auth) {
            if (Appl.User.hasRole(Role.Admin) || Appl.User.hasRole(Role.Member)) {
                if (Appl.Setting.EnvironmentName !== "prod") {
                    navigate('/sra');
                } else {
                    navigate('/members/death-notifications');
                }

            } else {
                navigate('/members/upload-requests');
            }
        } else {
            if (authCode.includes("#code=")) {
                navigate('/redirect');
                // window.location.reload();
            }
        }
    }, []);

    return (
        <View id="LoginView" pageTitle="Redirecting..." authorized={true} showMenuBar={false} container={false}>
            <Container>
                <div className="p-10 max-w-lg mx-auto ">
                    <h1 className="text-3xl font-semibold mb-4">Login to continue</h1>
                </div>
            </Container>
        </View>
    )
})
export default RedirectView