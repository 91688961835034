import React from 'react';
import { observer } from 'mobx-react';
import { DataGrid, Icon, SearchToolbar, View, ViewHeader } from '../../../../../../core/components/Index';
import { useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { Column } from 'primereact/column';
import { formatDate } from '../../../../../../core/services/Helper';
import { Appl } from '../../../../../../core/Appl';
import DeathReportListViewModel from './DeathReportListViewModel';
import { DeathReportListModel } from './DeathReportListModel';
import { FileInput } from 'lucide-react';
import { Button as PrimeButton } from 'primereact/button';
import { DataTableSelectEvent } from 'primereact/datatable';

export const DeathReportListView: React.FC = observer(() => {
  const vm = Appl.Services.get<DeathReportListViewModel>('DeathReportListViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.getPagedAsync();
  }, []);


  const createdDateTemplate = (row: DeathReportListModel) => {
    return formatDate(row.createdDate);
  };
  const birthDateTemplate = (row: DeathReportListModel) => {
    return formatDate(row.mbrBirthDate);
  };

  const deathDateTemplate = (row: DeathReportListModel) => {
    return formatDate(row.mbrDeathDate);
  };
  const contactTemplate = (row: DeathReportListModel) => {
    return `${row.reqFirstName} ${row.reqLastName}`;
  };


  // const actionTemplate = (row: DeathReportListModel) => {
  //   let editLink = `/members/death-report/${row.mbrDeathReportId}`;
  //   return (
  //     <div className="flex">
  //       <Link to={editLink} title="Edit Death Report">
  //         <Icon name="file-pen-line" size={24} className="link me-2" title="Edit" />
  //       </Link>
  //     </div>
  //   );
  // };

  const onRowSelect = (event: DataTableSelectEvent) => {
    let editLink = `/members/death-report/${event.data.mbrDeathReportId}`;
    vm.navigate(editLink);
  };

  return (
    <>
      <View id="DeathReportListView" pageTitle={vm.pageTitle} authorized={Appl.User.isAuthenticated}
        showMenuBar={false}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription}>
          <SearchToolbar
            filterPlaceholder="ID / Name / SSN / Contact"
            filter={vm.searchRequest?.filter}
            onFilterChange={(e) => vm.onInputChange(e, 'filter', vm.searchRequest)}
            onRefresh={async () => await vm.getPagedAsync()}
            addNewVisible={false}
            refreshVisible={false}
            showButton={false}
            // run search on input change
            searchOnInputChange={true}
          />
        </ViewHeader>
        <div className="flex space-x-1">
          <PrimeButton outlined severity="secondary" onClick={async () => await vm.export('csv')}
            className="!border-transparent !bg-transparent">
            <FileInput className="w-4 h-4 mr-1.5" />
            Export
          </PrimeButton>
        </div>
        <DataGrid
          pageSize={vm.pageSize}
          dataSource={vm.items}
          totalRowsCount={vm.totalRowsCount}
          searchRequest={vm.searchRequest}
          onRefresh={() => vm.getPagedAsync()}
          selectionMode='single'
          onRowSelect={onRowSelect}
        >
          <Column field="mbrDeathReportId" header="ID" sortable={true} hidden={false} />
          <Column field="pipeline" header="PIPELINE" sortable={false} body="Death Notifications" />
          <Column field="createdDate" header="RECEIVED" sortable={true} body={createdDateTemplate} />
          <Column field="mbrFirstName" header="FIRST NAME" sortable={true} />
          <Column field="mbrLastName" header="LAST NAME" sortable={true} />
          <Column field="mbrSsn" header="SSN" sortable={true} />
          <Column field="mbrDeathDate" header="DOD" sortable={true} body={deathDateTemplate} />
          <Column field="mbrBirthDate" header="DOB" sortable={true} body={birthDateTemplate} />
          <Column field="reqFirstName" header="CONTACT NAME" sortable={true} body={contactTemplate} />
          <Column field="submissionStatus" header="STATUS" sortable={true} />
        </DataGrid>
      </View>
    </>
  );
});
