import { BaseViewModel } from "../../../../../core/services/BaseViewModel";
import { action, makeObservable, observable, runInAction } from "mobx";
import { Service } from "typedi";
import { ISraService } from "../../SraService";
import { AppForRetirementModel, BeneficiaryModel, Steps, StepStatuses, SubmissionModel } from "./ApplyModels";
import { ListItem, ServerResponse } from "../../../../../core/services/BaseService";
import Helper, { toLocalDate, toServerDate } from "../../../../../core/services/Helper";
import { DocumentsModel } from "../../../../../core/components/UploadUtility";
import { StepStatus } from "./SidebarFormNavigation";
import { Appl } from "../../../../../core/Appl";

import { AccountType, BeneficiaryPaymentOption, BeneficiaryType, PersonalStatus, SubmissionStatus, SupportedDocuments } from "../../SraEnums";
import { ILovService } from "../../../utility/lov/LovService";

@Service() // eslint-disable-next-line
export default class ApplyViewModel extends BaseViewModel {

    @observable public memberId?: string;
    @observable public submissionId?: number;
    @observable public printDialogVisible = false;
    @observable public submission?: SubmissionModel = {}
    @observable public stepStatus: StepStatuses = {
        gettingStarted: StepStatus.NotStarted,
        selectDate: StepStatus.NotStarted,
        yourInformation: StepStatus.NotStarted,
        personalInformation: StepStatus.NotStarted,
        addressInformation: StepStatus.NotStarted,
        contactInformation: StepStatus.NotStarted,
        personalStatus: StepStatus.NotStarted,
        optionSelection: StepStatus.NotStarted,
        optionSelectionSelectYourOption: StepStatus.NotStarted,
        optionSelectionMemberDeclaration: StepStatus.NotStarted,
        beneficiaryDesignation: StepStatus.NotStarted,
        beneficiaryImportantInformation: StepStatus.NotStarted,
        beneficiaryMemberDeclaration: StepStatus.NotStarted,
        taxWithholding: StepStatus.NotStarted,
        federalTaxWithholding: StepStatus.NotStarted,
        stateTaxWithholding: StepStatus.NotStarted,
        declarationTaxWithholding: StepStatus.NotStarted,
        directDeposit: StepStatus.NotStarted,
        directDepositAuthorization: StepStatus.NotStarted,
        directDepositMemberDeclaration: StepStatus.NotStarted,
        temporaryAnnuity: StepStatus.NotStarted,
        serviceRetirementSignOff: StepStatus.NotStarted,
        reviewApplication: StepStatus.NotStarted,
        uploadDocuments: StepStatus.NotStarted,
        submitApplication: StepStatus.NotStarted,
    }

    @observable public model: AppForRetirementModel = {
        submissionId: 0,
        submissionStatusId: SubmissionStatus.Draft,
        personalInformation: { submissionId: 0, validateAddress: false },
        optionSelection: { submissionId: 0, beneficiaryPaymentOptionDeclaration: false, beneficiaryPaymentOptionId: 0 },
        temporaryAnnuity: { submissionId: 0 },
        beneficiaryDesignation: { afrDecleration: false },
        taxWithHolding: {
            federalTaxWithHolding: { federalTaxWithhold: "N" },
            stateTaxWithHolding: { stateTaxWithhold: 'N' },
            taxwithholdDecleration: false
        },
        directDeposit: { directDepositConsent: false },
        accruedSickLeave: { sickLeaveCreditN: true, sickLeaveCredit: false },
        screaMembership: { regularMembership: false, associateMembership: false, acceptMembershipConsent: false },
        signOffModels: [],
        uploadedDocuments: []
    };
    @observable public modelLoaded = false;
    @observable public retirementDateConfirmed = false;
    @observable public beneficiaryDesignationCompleted = false;
    @observable public addressEditable = false;
    @observable public contactInfoEditable = true;
    @observable public yourWrongInformation = false;
    @observable public alternativeNumberEditable = false;
    @observable public isAddingBeneficiary = false;
    @observable public isEditingBeneficiary = false;
    @observable public beneficiaryValidationMessage = "";
    @observable public beneficiaryModel: BeneficiaryModel;
    @observable public selectedBeneficiary = 0;
    @observable public taxWithHoldingModelCompleted = false;
    @observable public directDepositModelCompleted = false;
    @observable public accruedSickLeaveModelCompleted = false;
    @observable public screaMembershipModelCompleted = false;
    @observable public signOffCheckedItemsCount = 0;
    @observable public documentUploadModelCompleted = false;
    @observable public activeDocument: DocumentsModel = { docTypeId: 0 };
    @observable public uploadDialogVisible: boolean = false;

    @observable public addressListItems?: Array<ListItem> = [];

    @observable public afrFile?: any
    @observable public navigatedFromReviewScreen: boolean = false;
    @observable requiredDocuments = [];
    private _service: ISraService;
    private lovService: ILovService;

    constructor() {
        super();
        this._service = Appl.Services.get<ISraService>('ISraService');
        this.lovService = Appl.Services.get<ILovService>('ILovService');
        this.beneficiaryModel = {};
        makeObservable(this);
    }

    @action public async getSubmissionIdAsync(): Promise<number> {
        const submissionId = parseInt(Appl.Cache.get("submission_id")!);
        return submissionId;
    }

    @action public async getStepStatuses(memberId: string): Promise<void> {
        this.memberId = memberId;
        let result = await this._service?.getSubmissionStepStatus(memberId);
        if (result.success) {
            runInAction(async () => {
                this.stepStatus = result.data;
            });
        }
    }

    @action public async loadStepAsync(step: Steps, memberId: string): Promise<void> {
        this.memberId = memberId;
        let result: ServerResponse;
        Appl.Cache.remove("submission_id");
        this.modelLoaded = false;
        result = await this._service?.getSubmissionByMemberIdAsync(memberId, true);
        if (result.success) {
            runInAction(async () => {
                this.submission = result.data;
                this.submissionId = this.submission?.submissionId;
                if (this.submission?.lovSubmissionStatusId !== SubmissionStatus.Draft) {
                    this.navigate(`/sra/retiree/afr-status/${this.memberId}`)
                }
                Appl.Cache.addOrUpdate("submission_id", this.submissionId!.toString());

                this.submissionId = await this.getSubmissionIdAsync();
                if (!this.modelLoaded) {
                    await this.getStepStatuses(memberId);
                    result = await this._service?.getAppForRetirementBySubmissionIdAsync(this.submissionId);
                    if (result.success) {
                        runInAction(() => {
                            this.model = result.data;
                            if (this.model) {
                                //Personal Information
                                this.model.personalInformation.birthYear = Helper.getDayMonthYearString(this.model.personalInformation.birthDate).year;
                                this.model.personalInformation.birthMonth = Helper.getDayMonthYearString(this.model.personalInformation.birthDate).month;
                                this.model.personalInformation.birthDay = Helper.getDayMonthYearString(this.model.personalInformation.birthDate).day;
                                this.model.personalInformation.retirementEffYear = Helper.getDayMonthYearString(this.model.personalInformation.retirementEffDate).year;
                                this.model.personalInformation.retirementEffMonth = Helper.getDayMonthYearString(this.model.personalInformation.retirementEffDate).month;
                                this.model.personalInformation.retirementEffDay = Helper.getDayMonthYearString(this.model.personalInformation.retirementEffDate).day;
                                this.model.personalInformation.marriageDate = toLocalDate(this.model.personalInformation.marriageDate);
                                this.model.personalInformation.partnershipDate = toLocalDate(this.model.personalInformation.partnershipDate);
                                this.model.personalInformation.ssnMasked = `XXXX-XX-${this.model.personalInformation.ssn}`
                                if (this.model.personalInformation.alternatePhone) {
                                    this.alternativeNumberEditable = true;
                                }

                                //Beneficiary Designation
                                if (this.model.beneficiaryDesignation.beneficiaries) {
                                    this.selectedBeneficiary = 0;
                                    this.beneficiaryModel = this.model.beneficiaryDesignation.beneficiaries[this.selectedBeneficiary];
                                }
                                this.model.accruedSickLeave.sickLeaveCreditN = !this.model.accruedSickLeave.sickLeaveCredit;
                                //Sign Off Model
                                this.signOffCheckedItemsCount = this.model.signOffModels.filter(o => o.active)?.length;
                                // this.model.phoneTypeListItems?.unshift({ key: '', value: '' });
                            }

                            this.modelLoaded = true;
                        })
                    } else {
                        Appl.Error.handle(result.error);
                        return;
                    }
                }
                await this.setStepStatus(step, StepStatus.Active);
            })
        } else {
            Appl.Error.handle(result.error);
            return;
        }
    }

    private async setStepStatus(step: Steps, status: StepStatus): Promise<void> {
        if (status === StepStatus.Active) {
            this.beneficiaryValidationMessage = "";
            this.isAddingBeneficiary = false;
            this.isEditingBeneficiary = false;
            if (step === Steps.BeneficiaryImportantInformation) {
                if (this.model.beneficiaryDesignation.beneficiaries?.length! > 0) {
                    await this.navigateToStepAsync(`${Steps.BeneficiaryDesignation}/${Steps.AddBeneficiary}`)
                }
            } else if (step === Steps.AddBeneficiary) {
                if (this.model.beneficiaryDesignation.beneficiaries?.length! === 0) {
                    // this.isAddingBeneficiary = true;
                    await this.addBeneficiaryAsync(false);
                }
            }
            return;
        }
        switch (step) {
            case Steps.GettingStarted: {
                this.stepStatus.gettingStarted = status;
                break;
            }
            case Steps.SelectDate: {
                this.stepStatus.selectDate = status;
                this.retirementDateConfirmed = false;
                break;
            }
            case Steps.YourInformation: {
                this.yourWrongInformation = false;
                this.stepStatus.yourInformation = status;
                break;
            }
            case Steps.PersonalInformation: {
                this.yourWrongInformation = false;
                this.stepStatus.yourInformation = StepStatus.InProgress;
                this.stepStatus.personalInformation = status;
                break;
            }
            case Steps.AddressInformation: {
                this.stepStatus.yourInformation = StepStatus.InProgress;
                this.stepStatus.addressInformation = status;
                break;
            }
            case Steps.ContactInformation: {
                this.stepStatus.yourInformation = StepStatus.InProgress;
                this.stepStatus.contactInformation = status;
                break;
            }
            case Steps.PersonalStatus: {
                if (status === StepStatus.Completed) {
                    this.stepStatus.yourInformation = StepStatus.Completed;
                } else {
                    this.stepStatus.yourInformation = StepStatus.InProgress;
                }
                this.stepStatus.personalStatus = status;
                break;
            }
            case Steps.OptionSelection: {
                this.stepStatus.optionSelection = StepStatus.InProgress;
                break;
            }
            case Steps.OptionSelectionSelectYourOption: {
                if (status === StepStatus.Completed) {
                    this.stepStatus.optionSelection = StepStatus.Completed;
                } else {
                    this.stepStatus.optionSelection = StepStatus.InProgress;
                }
                this.stepStatus.optionSelectionSelectYourOption = status;
                break;
            }
            case Steps.OptionSelectionMemberDeclaration: {
                if (status === StepStatus.Completed) {
                    this.stepStatus.optionSelection = StepStatus.Completed;
                } else {
                    this.stepStatus.optionSelection = StepStatus.InProgress;
                }
                this.stepStatus.optionSelectionMemberDeclaration = status;
                break;
            }
            case Steps.BeneficiaryDesignation: {
                this.beneficiaryDesignationCompleted = false;
                this.stepStatus.beneficiaryDesignation = StepStatus.InProgress;
                break;
            }
            case Steps.BeneficiaryImportantInformation: {
                this.beneficiaryDesignationCompleted = false;
                if (status === StepStatus.Completed) {
                    this.stepStatus.beneficiaryDesignation = StepStatus.Completed;
                } else {
                    this.stepStatus.beneficiaryDesignation = StepStatus.InProgress;
                }
                this.stepStatus.beneficiaryImportantInformation = status;
                break;
            }
            case Steps.AddBeneficiary: {
                this.beneficiaryDesignationCompleted = false;
                if (status === StepStatus.Completed) {
                    this.stepStatus.beneficiaryDesignation = StepStatus.Completed;
                } else {
                    this.stepStatus.beneficiaryDesignation = StepStatus.InProgress;
                }
                this.stepStatus.addBeneficiary = status;
                break;
            }
            case Steps.BeneficiaryMemberDeclaration: {

                if (status === StepStatus.Completed) {
                    this.stepStatus.beneficiaryDesignation = StepStatus.Completed;
                } else {
                    this.stepStatus.beneficiaryDesignation = StepStatus.InProgress;
                }
                this.stepStatus.beneficiaryMemberDeclaration = status;
                this.beneficiaryDesignationCompleted = false;
                break;
            }
            case Steps.TaxWithholding: {
                this.stepStatus.taxWithholding = StepStatus.InProgress;
                break;
            }
            case Steps.FederalTaxWithholding: {
                if (status === StepStatus.Completed) {
                    this.stepStatus.taxWithholding = StepStatus.Completed;
                } else {
                    this.stepStatus.taxWithholding = StepStatus.InProgress;
                }
                this.stepStatus.federalTaxWithholding = status;
                break;
            }
            case Steps.StateTaxWithholding: {
                if (status === StepStatus.Completed) {
                    this.stepStatus.taxWithholding = StepStatus.Completed;
                } else {
                    this.stepStatus.taxWithholding = StepStatus.InProgress;
                }
                this.stepStatus.stateTaxWithholding = status;
                break;
            }
            case Steps.DeclarationTaxWithholding: {
                if (status === StepStatus.Completed) {
                    this.stepStatus.taxWithholding = StepStatus.Completed;
                } else {
                    this.stepStatus.taxWithholding = StepStatus.InProgress;
                }
                this.stepStatus.declarationTaxWithholding = status;
                this.taxWithHoldingModelCompleted = false;
                break;
            }
            case Steps.DirectDeposit: {
                this.stepStatus.directDeposit = StepStatus.InProgress;
                this.directDepositModelCompleted = false;
                break;
            }
            case Steps.DirectDepositAuthorization: {
                if (status === StepStatus.Completed) {
                    this.stepStatus.directDeposit = StepStatus.Completed;
                } else {
                    this.stepStatus.directDeposit = StepStatus.InProgress;
                }
                this.stepStatus.directDepositAuthorization = status;
                break;
            }
            case Steps.DirectDepositMemberDeclaration: {
                if (status === StepStatus.Completed) {
                    this.stepStatus.directDeposit = StepStatus.Completed;
                } else {
                    this.stepStatus.directDeposit = StepStatus.InProgress;
                }
                this.stepStatus.directDepositMemberDeclaration = status;
                break;
            }
            case Steps.TemporaryAnnuity: {
                this.stepStatus.temporaryAnnuity = status;
                break;
            }
            case Steps.ServiceRetirementSignOff: {
                this.stepStatus.serviceRetirementSignOff = status;
                break;
            }
            case Steps.ReviewApplication: {
                this.stepStatus.reviewApplication = status;
                break;
            }
            case Steps.UploadDocuments: {
                this.stepStatus.uploadDocuments = status;
                this.documentUploadModelCompleted = false;
                break;
            }
            case Steps.SubmitApplication: {
                this.stepStatus.submitApplication = status;
                break;
            }
            default: {
                break;
            }
        }

        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.stepStatus);
    }

    @action public async onGettingStartedStepChangeAsync(): Promise<boolean> {
        this.setStepStatus(Steps.GettingStarted, StepStatus.Completed);
        return true;
    }

    @action public async onSelectDateChangeAsync(): Promise<boolean> {
        this.submissionId = await this.getSubmissionIdAsync();
        this.model.personalInformation.submissionId = this.submissionId;
        Appl.Validator.init();
        Appl.Validator.validateDateOnly("Retirement Date", "retirementEffDate", this.model.personalInformation.retirementEffYear, this.model.personalInformation.retirementEffMonth, this.model.personalInformation.retirementEffDay, true);
        if (!Appl.Validator.isValid()) { return false; }

        this.model.personalInformation.retirementEffDate = Helper.getDateString(this.model.personalInformation.retirementEffYear!, this.model.personalInformation.retirementEffMonth!, this.model.personalInformation.retirementEffDay!);
        if (new Date(this.model.personalInformation.retirementEffDate) <= new Date()) {
            await Appl.Validator.setError("retirementEffDate", "Retirement Date should be future date.");
            return false;
        }

        let days = Helper.getDays(new Date(), new Date(this.model.personalInformation.retirementEffDate));
        if (days > 60) {
            Appl.MessageBox.show(`You have selected a retirement date that is more than 60 days from today. You may continue with the Online Retirement Application; however, please be aware that you will not be able to electronically sign and submit your application until you are within 60 days of your selected retirement date.<br/><br/>Rest assured, any progress you make on your application- whether in part or in whole-will be saved. You can return at any time to complete the submission process when you become eligible to do so..`, 'Retirement Date Notification', '', 'I Understand');
        }
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (result.success) {
            this.setStepStatus(Steps.SelectDate, StepStatus.Completed);
            this.retirementDateConfirmed = true;
            return true;
        } else {
            await Appl.Validator.setError("retirementEffDate", result.error?.detail!);
            return false;
        }
    }

    @action public async onPersonalInformationStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();
        await Appl.Validator.validateString('First Name', 'firstName', this.model.personalInformation.firstName, true, 100);
        await Appl.Validator.validateString('Middle Name', 'middleName', this.model.personalInformation.middleName, false, 10);
        await Appl.Validator.validateString('Last Name', 'lastName', this.model.personalInformation.lastName, true, 100);
        Appl.Validator.validateDateOnly("Birth Date", "BirthDate", this.model.personalInformation.birthYear, this.model.personalInformation.birthMonth, this.model.personalInformation.birthDay, true);
        if (!Appl.Validator.isValid()) { return false; }
        this.model.personalInformation.birthDate = Helper.getDateString(this.model.personalInformation.birthYear!, this.model.personalInformation.birthMonth!, this.model.personalInformation.birthDay!);
        // this.model.personalInformation.birthDate = Helper.toServerDateTime(this.model.personalInformation.birthDate);
        this.model.personalInformation.personalInfoVerified = 'Y';
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.PersonalInformation, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.YourInformation}/${Steps.AddressInformation}`)
        return true;
    }

    @action public async onAddressInformationStepChangeAsync(): Promise<boolean> {
        if (!(this.model.personalInformation.homeRawAddress || this.model.personalInformation.mailingRawAddress)) {
            this.showError("Please add address.");
            return false;
        }
        this.setStepStatus(Steps.AddressInformation, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.YourInformation}/${Steps.ContactInformation}`);
        return true;
    }

    @action public async setAddressEditable(editable: boolean): Promise<void> {
        this.addressEditable = editable;
    }

    @action public async onAddressAddEditAsync(): Promise<boolean> {

        Appl.Validator.init();
        await Appl.Validator.validateString('Home Address', 'homeRawAddress', this.model.personalInformation.homeRawAddress, true, 200);
        await Appl.Validator.validateString('Home Country', 'homeCountry', this.model.personalInformation.homeCountry, true, 50);

        await Appl.Validator.validateString('Mailing Address', 'mailingRawAddress', this.model.personalInformation.mailingRawAddress, false, 200);
        await Appl.Validator.validateString('Mailing Country', 'mailingCountry', this.model.personalInformation.mailingCountry, false, 50);

        if (!Appl.Validator.isValid()) { return false; }
        this.model.personalInformation.validateAddress = true;
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        let data = result.data;
        this.model.personalInformation.validateAddress = false;
        this.model.personalInformation.homeRawAddress = data.homeRawAddress;
        this.model.personalInformation.mailingRawAddress = data.mailingRawAddress;
        this.addressEditable = false;
        return true;
    }

    @action public async setAlternativeNumberEditable(editable: boolean): Promise<void> {
        this.alternativeNumberEditable = editable;
        if (!editable) {
            this.model.personalInformation.alternatePhone = undefined;
            this.model.personalInformation.alternatePhoneType = undefined;
        }
    }

    @action public async onContactInformationStepChangeAsync(): Promise<boolean> {
        if (this.contactInfoEditable) {
            Appl.Validator.init();
            await Appl.Validator.validatePhone('Primary Phone Number', 'phone', this.model.personalInformation.phone, true, 20);
            await Appl.Validator.validateString('Phone Type', 'phoneType', this.model.personalInformation.phoneType, true, 10);
            if (this.alternativeNumberEditable) {
                await Appl.Validator.validatePhone('Alternate Phone Number', 'alternatePhone', this.model.personalInformation.alternatePhone, true, 20);
                await Appl.Validator.validateString('Alternate Phone Type', 'alternatePhoneType', this.model.personalInformation.alternatePhoneType, true, 10);
            }
            if (!Appl.Validator.isValid()) { return false; }
            const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
            if (!result.success) {
                Appl.Error.handle(result.error);
                return false;
            }
            this.contactInfoEditable = false;
            return true;
        } else {
            this.setStepStatus(Steps.ContactInformation, StepStatus.Completed);
            this.contactInfoEditable = true;
            await this.navigateToStepAsync(`${Steps.YourInformation}/${Steps.PersonalStatus}`)
            return true;
        }
    }

    @action public async onPersonalStatusStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();
        await Appl.Validator.validateString('Personal Status', 'lovPersonalStatusId', this.model.personalInformation.lovPersonalStatusId, true, 10);
        if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Married) {//Married
            await Appl.Validator.validateDate('Date of Marriage', 'marriageDate', this.model.personalInformation.marriageDate, true, '');
            if (this.model.personalInformation.marriageDate && new Date(this.model.personalInformation.marriageDate!) > new Date()) {
                Appl.Validator.setError("marriageDate", "Marriage date should not be a future date.");
            }
        } else if (this.model.personalInformation.lovPersonalStatusId == PersonalStatus.RegistedDomesticPartner) {//Registered Domestic Partnership
            await Appl.Validator.validateDate('Date of Partnership', 'partnershipDate', this.model.personalInformation.partnershipDate, true, '');
            if (this.model.personalInformation.partnershipDate && new Date(this.model.personalInformation.partnershipDate!) > new Date()) {
                Appl.Validator.setError("partnershipDate", "Partnership date should not be a future date.");
            }
        }

        if (!Appl.Validator.isValid()) { return false; }
        this.model.personalInformation.marriageDate = toServerDate(this.model.personalInformation.marriageDate);
        this.model.personalInformation.partnershipDate = toServerDate(this.model.personalInformation.partnershipDate);
        const result = await this._service?.createOrUpdatePersonalInfoAsync(this.model.personalInformation);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.PersonalStatus, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.OptionSelection}/${Steps.OptionSelectionSelectYourOption}`)
        return true;
    }

    @action public async onOptionSelectionSelectYourOptionStepAsync(): Promise<boolean> {
        const result = await this._service?.createOrUpdateOptionSelectionAsync(this.model.optionSelection);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.OptionSelectionSelectYourOption, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.OptionSelection}/${Steps.OptionSelectionMemberDeclaration}`)
        return true;
    }

    @action public async onOptionSelectionMemberDeclarationStepAsync(): Promise<boolean> {
        const result = await this._service?.createOrUpdateOptionSelectionAsync(this.model.optionSelection);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.OptionSelectionMemberDeclaration, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.BeneficiaryDesignation}/${Steps.BeneficiaryImportantInformation}`)
        return true;
    }

    @action public async onBeneficiaryImportantInformationStepCompletedAsync(): Promise<boolean> {
        this.setStepStatus(Steps.BeneficiaryImportantInformation, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.BeneficiaryDesignation}/${Steps.AddBeneficiary}`)
        return true;
    }

    @action async addBeneficiaryAsync(isSpouse: boolean = false): Promise<void> {
        this.isAddingBeneficiary = true;
        this.beneficiaryModel = {};
        if (this.model.beneficiaryDesignation.beneficiaries) {
            this.model.beneficiaryDesignation.beneficiaries.push({ afrMbrAffidavitId: this.model.beneficiaryDesignation.afrMbrAffidavitId });
            this.selectedBeneficiary = this.model.beneficiaryDesignation.beneficiaries.length - 1;
            this.beneficiaryModel = this.model.beneficiaryDesignation.beneficiaries[this.selectedBeneficiary];
        }
        this.beneficiaryModel.lovBeneRelationshipId = isSpouse ? BeneficiaryType.Spouse : undefined;
    }

    @action async editBeneficiaryAsync(index: number): Promise<void> {
        this.isEditingBeneficiary = true;
        this.selectedBeneficiary = index;
        if (this.model.beneficiaryDesignation.beneficiaries) {
            this.beneficiaryModel = this.model.beneficiaryDesignation.beneficiaries[index] as BeneficiaryModel;
            this.beneficiaryModel.beneBirthDateYear = Helper.getDayMonthYearString(this.beneficiaryModel.beneBirthDate).year;
            this.beneficiaryModel.beneBirthDateMonth = Helper.getDayMonthYearString(this.beneficiaryModel.beneBirthDate).month;
            this.beneficiaryModel.beneBirthDateDay = Helper.getDayMonthYearString(this.beneficiaryModel.beneBirthDate).day;
        }
    }

    @action async removeBeneficiaryAsync(index: number): Promise<void> {
        this.selectedBeneficiary = index;
        this.confirmDialogOperation = 'REMOVE_BENEFICIARY';
        this.confirmDialogTitle = "Remove Beneficiary";
        this.confirmDialogMessage = 'Are you sure you want to remove this beneficiary?';
        this.showConfirmDialog = true;
    }

    @action async cancelAddBeneficiaryAsync(): Promise<void> {
        if (this.isAddingBeneficiary) {
            if (this.selectedBeneficiary > -1 && this.model.beneficiaryDesignation.beneficiaries && this.model.beneficiaryDesignation.beneficiaries.length > 0) {
                this.model.beneficiaryDesignation.beneficiaries.splice(this.selectedBeneficiary, 1);
            }
        }
        this.isAddingBeneficiary = false;
        this.isEditingBeneficiary = false;
        this.selectedBeneficiary = -1;
    }

    @action
    public async createOrUpdateBeneficiaryAsync(): Promise<boolean> {
        Appl.Validator.init();
        this.beneficiaryValidationMessage = "";
        this.model.beneficiaryDesignation.submissionId = this.submissionId;
        await Appl.Validator.validateNumber('Relationship', `lovBeneRelationshipId`, this.beneficiaryModel.lovBeneRelationshipId, true);
        await Appl.Validator.validateNumber('Percentage', `percentage`, this.beneficiaryModel.percentage, true, 100, 1);
        if (this.beneficiaryModel.lovBeneRelationshipId !== BeneficiaryType.EntityTrustOrg) {//Trust/Organization
            Appl.Validator.validateDateOnly("Birth Date", "beneBirthDate", this.beneficiaryModel.beneBirthDateYear, this.beneficiaryModel.beneBirthDateMonth, this.beneficiaryModel.beneBirthDateDay, true);
            if (Appl.Validator.isValid()) {
                this.beneficiaryModel.beneBirthDate = Helper.getDateString(this.beneficiaryModel.beneBirthDateYear!, this.beneficiaryModel.beneBirthDateMonth!, this.beneficiaryModel.beneBirthDateDay!);
                if (new Date(this.beneficiaryModel.beneBirthDate) >= new Date()) {
                    await Appl.Validator.setError("beneBirthDate", "Birth Date should not be greater than today date.");
                }
                if (this.beneficiaryModel.lovBeneRelationshipId === BeneficiaryType.MinorDependentChild
                ) {
                    let age = Helper.getYears(this.beneficiaryModel.beneBirthDate, new Date())
                    if (age > 21) {
                        await Appl.Validator.setError("beneBirthDate", "Child should be less than 21 years old.");
                    }
                }
            }
            await Appl.Validator.validateString('First Name', `beneFirstName`, this.beneficiaryModel.beneFirstName, true, 100);
            await Appl.Validator.validateString('Last Name', `beneLastName`, this.beneficiaryModel.beneLastName, true, 100);
            await Appl.Validator.validateNumber('Social Security Number', `beneSsn`, this.beneficiaryModel.beneSsn, true, 999999999);
            this.beneficiaryModel.beneOtherName = "";
            this.beneficiaryModel.beneTin = "";
        } else {
            await Appl.Validator.validateString('Other Name', `beneOtherName`, this.beneficiaryModel.beneOtherName, true, 50);
            await Appl.Validator.validateNumber('Tax Identification Number', `beneTin`, this.beneficiaryModel.beneTin, true, 999999999);
            this.beneficiaryModel.beneFirstName = "";
            this.beneficiaryModel.beneLastName = "";
            this.beneficiaryModel.beneSsn = "";
            this.beneficiaryModel.beneBirthDate = undefined;
        }
        await Appl.Validator.validateString('Address', `beneRawAddress`, this.beneficiaryModel.beneRawAddress, true, 350);
        await Appl.Validator.validateString('Country', `beneCountry`, this.beneficiaryModel.beneCountry, true, 50);
        if (!Appl.Validator.isValid()) {
            return false;
        }
        this.beneficiaryModel.afrMbrAffidavitId = this.model.beneficiaryDesignation.afrMbrAffidavitId;
        const result = await this._service?.createOrUpdateBeneficiaryAsync(this.beneficiaryModel);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false
        } else {
            runInAction(() => {
                this.beneficiaryModel.afrBeneId = result.data;
            })
        }
        this.isAddingBeneficiary = false;
        this.isEditingBeneficiary = false;
        this.selectedBeneficiary = -1;
        this.stepStatus.addBeneficiary = StepStatus.Completed;
        await this._service?.updateSubmissionStepStatus(this.submissionId!, this.stepStatus);
        return true;
    }

    @action
    public getBeneficiaryRelationshipListItems(): Array<ListItem> {
        let items = this.model.beneficiaryRelationListItems;
        if (!items) {
            items = []
        }
        //MARRIED (for >= 1 year)::This means "Married" and marriage_date > 1 year
        if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Married) {//Married

            let age = Helper.getYears(this.model.personalInformation.marriageDate, new Date())
            if (age > 1) {//MARRIED
                if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.UnmodifiedAllowance) {//Unmodified Allowance
                    items = items.filter(o => (o.key === BeneficiaryType.Spouse || o.key === BeneficiaryType.MinorDependentChild));//Spouse or Minor*
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option1) {//Option 1: Lump Sum Benefit to Beneficiary; No Monthly Continuance
                    items = items.filter(o => (o.key === BeneficiaryType.Spouse || o.key === BeneficiaryType.MinorDependentChild || o.key === BeneficiaryType.EntityTrustOrg || o.key === BeneficiaryType.Others));//Spouse or Minor or Other individual (with spousal consent) or Entity (e.g. Trust, Organization, etc) (with spousal consent)
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option2) {//Option 2: 100% Joint and Survivor Annuity
                    items = items.filter(o => (o.key === BeneficiaryType.Spouse || o.key === BeneficiaryType.InsurableInterest));//Spouse or Insurable Interest
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option3) {//Option 3: 50% Joint and Survivor Annuity
                    items = items.filter(o => (o.key === BeneficiaryType.Spouse || o.key === BeneficiaryType.InsurableInterest));//Spouse or Insurable Interest
                }
            } else {//NOT MARRIED :: This means "Married" and marriage_date < 1 year or any other value 
                if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.UnmodifiedAllowance) {//Unmodified Allowance
                    items = items.filter(o => (o.key === BeneficiaryType.MinorDependentChild || o.key === BeneficiaryType.EntityTrustOrg || o.key === BeneficiaryType.Others));//Minor or Other Individual (lump sum) or Entity (e.g. Trust, Organization, etc) (lump sum)
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option1) {//Option 1: Lump Sum Benefit to Beneficiary; No Monthly Continuance
                    items = items.filter(o => (o.key === BeneficiaryType.EntityTrustOrg || o.key === BeneficiaryType.Others));//Other individual or Entity (e.g. Trust, Organization, etc)
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option2) {//Option 2: 100% Joint and Survivor Annuity
                    items = items.filter(o => (o.key === BeneficiaryType.InsurableInterest));//Insurable Interest
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option3) {//Option 3: 50% Joint and Survivor Annuity
                    items = items.filter(o => (o.key === BeneficiaryType.InsurableInterest));//Insurable Interest
                }
            }
        }//PARTNERED (for >= 1 year)::This means "Registered Domestic Partner" and partnership_date > 1 year
        else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.RegistedDomesticPartner) {//Registered Domestic Partnership

            let age = Helper.getYears(this.model.personalInformation.partnershipDate, new Date())
            if (age > 1) {//PARTNERED :: This means "Registered Domestic Partner" and partnership_date > 1 year
                if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.UnmodifiedAllowance) {//Unmodified Allowance
                    items = items.filter(o => (o.key === BeneficiaryType.RegisteredDomesticPartner || o.key === BeneficiaryType.MinorDependentChild));//Registered Domestic Partner or Dependent Child(ren) *
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option1) {//Option 1: Lump Sum Benefit to Beneficiary; No Monthly Continuance
                    items = items.filter(o => (o.key === BeneficiaryType.RegisteredDomesticPartner || o.key === BeneficiaryType.MinorDependentChild || o.key === BeneficiaryType.EntityTrustOrg || o.key === BeneficiaryType.Others));//Registered Domestic Partne or Dependent Child(ren) or Other individual (with spousal consent) or Entity (e.g. Trust, Organization, etc) (with spousal consent)
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option2) {//Option 2: 100% Joint and Survivor Annuity
                    items = items.filter(o => (o.key === BeneficiaryType.RegisteredDomesticPartner || o.key === BeneficiaryType.InsurableInterest));//Registered Domestic Partner or Insurable Interest
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option3) {//Option 3: 50% Joint and Survivor Annuity
                    items = items.filter(o => (o.key === BeneficiaryType.RegisteredDomesticPartner || o.key === BeneficiaryType.InsurableInterest));//Registered Domestic Partner or Insurable Interest
                }
            } else {//NOT PARTNERED :: This means "Registered Domestic Partner" and partnership_date < 1 year
                if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.UnmodifiedAllowance) {//Unmodified Allowance
                    items = items.filter(o => (o.key === BeneficiaryType.MinorDependentChild || o.key === BeneficiaryType.EntityTrustOrg || o.key === BeneficiaryType.Others));//Dependent Child(ren)  or Other Individual (lump sum) or Entity (e.g. Trust, Organization, etc) (lump sum)
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option1) {//Option 1: Lump Sum Benefit to Beneficiary; No Monthly Continuance
                    items = items.filter(o => (o.key === BeneficiaryType.EntityTrustOrg || o.key === BeneficiaryType.Others));//Other individual or Entity (e.g. Trust, Organization, etc)
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option2) {//Option 2: 100% Joint and Survivor Annuity
                    items = items.filter(o => (o.key === BeneficiaryType.InsurableInterest));//Insurable Interest
                } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option3) {//Option 3: 50% Joint and Survivor Annuity
                    items = items.filter(o => (o.key === BeneficiaryType.InsurableInterest));//Insurable Interest
                }
            }
        } else {
            if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.UnmodifiedAllowance) {//Unmodified Allowance
                items = items.filter(o => (o.key === BeneficiaryType.MinorDependentChild || o.key === BeneficiaryType.EntityTrustOrg || o.key === BeneficiaryType.Others));
            } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option1) {//Option 1: Lump Sum Benefit to Beneficiary; No Monthly Continuance
                items = items.filter(o => (o.key === BeneficiaryType.EntityTrustOrg || o.key === BeneficiaryType.Others));
            } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option2) {//Option 2: 100% Joint and Survivor Annuity
                items = items.filter(o => (o.key === BeneficiaryType.InsurableInterest));
            } else if (this.model.optionSelection.beneficiaryPaymentOptionId === BeneficiaryPaymentOption.Option3) {//Option 3: 50% Joint and Survivor Annuity
                items = items.filter(o => (o.key === BeneficiaryType.InsurableInterest));
            }
        }
        return items;
    }

    @action
    public async onAddBeneficiaryStepCompleteAsync(): Promise<boolean> {
        this.beneficiaryValidationMessage = "";
        let percentage: number = 0;
        this.model.beneficiaryDesignation.beneficiaries?.map((beneficiary, index) => {
            if (beneficiary.percentage) {
                percentage += parseInt(beneficiary.percentage.toString());
            }
        });
        if (percentage != 100) {
            this.beneficiaryValidationMessage = "The total percentage allocated to your beneficiaries <b>does not equal 100%</b>. Please adjust your allocations accordingly to ensure your benefits are fully distributed.";
            return false;
        }
        this.setStepStatus(Steps.AddBeneficiary, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.BeneficiaryDesignation}/${Steps.BeneficiaryMemberDeclaration}`)
        return true;
    }

    @action async setBeneficiaryMemberDeclarationStepCompleteAsync(agree: boolean): Promise<void> {

        if (agree) {
            if (!(this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Married
                || this.model.personalInformation.lovPersonalStatusId === PersonalStatus.RegistedDomesticPartner)) {
                this.showError("Please verify that your personal status is not set to either Married or Registered Domestic Partner.");
                return;
            }
        }
        const response = await this._service?.setMemberAggreedDeclarationStatusAsync(this.submissionId!, agree);
        if (response.success) {
            this.beneficiaryDesignationCompleted = true;
            this.setStepStatus(Steps.BeneficiaryMemberDeclaration, StepStatus.Completed);
            await this.navigateToStepAsync(Steps.TaxWithholding)
        } else {
            Appl.Error.handle(response.error);
        }
    }

    @action public async onConfirmDialogAsync(): Promise<void> {
        if (this.confirmDialogOperation === 'REMOVE_BENEFICIARY') {
            if (this.selectedBeneficiary > -1 && this.model.beneficiaryDesignation.beneficiaries && this.model.beneficiaryDesignation.beneficiaries.length > 0) {
                let ben = this.model.beneficiaryDesignation.beneficiaries[this.selectedBeneficiary];
                const benDeleteResponse = await this._service?.deleteBeneficiaryAsync(ben.afrBeneId!);
                if (benDeleteResponse.success) {
                    runInAction(() => {
                        this.model.beneficiaryDesignation.beneficiaries?.splice(this.selectedBeneficiary, 1);
                    });
                } else {
                    Appl.Error.handle(benDeleteResponse.error);
                }
            }
            this.selectedBeneficiary = -1;
        } else if (this.confirmDialogOperation === 'DELETE_DOCUMENT') {
            const result = await this._service?.deleteDocumentsAsync(this.activeDocument.docId!);
            if (!result.success) {
                Appl.Error.handle(result.error);
            } else {
                this.model.uploadedDocuments = this.model.uploadedDocuments?.filter(item => item !== this.activeDocument)
                this.showSuccess('Deleted Sucessfully');
            }
        }
        this.showConfirmDialog = false;
    }

    @action public onFederalTaxWithholdStatusChange(status: string, checked: boolean): void {

        if (this.model.taxWithHolding.federalTaxWithHolding) {
            if (status === "N" && checked) {
                this.model.taxWithHolding.federalTaxWithHolding.federalTaxWithhold = "N";
            } else if (status === "Y" && checked) {
                this.model.taxWithHolding.federalTaxWithHolding.federalTaxWithhold = "Y";
            }
            if (status === "N" && checked) {
                if (this.model.taxWithHolding) {
                    if (this.model.taxWithHolding.federalTaxWithHolding) {
                        this.model.taxWithHolding.federalTaxWithHolding.federalWithholdIncomeTaxStatus = undefined
                        this.model.taxWithHolding.federalTaxWithHolding.federalClaimsDependant = undefined;
                        this.model.taxWithHolding.federalTaxWithHolding.federalIncomeNotFromJob = undefined;
                        this.model.taxWithHolding.federalTaxWithHolding.federalDeductionsClaimed = undefined;
                        this.model.taxWithHolding.federalTaxWithHolding.federalAdditionalWithold = undefined;
                    }
                }
            }
        }
    }

    @action public async onFederalTaxWithholdStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();
        if (this.model.taxWithHolding.federalTaxWithHolding?.federalTaxWithhold === "Y") {
            if (this.model.taxWithHolding.federalTaxWithHolding?.federalWithholdIncomeTaxStatus === undefined
                || this.model.taxWithHolding.federalTaxWithHolding?.federalWithholdIncomeTaxStatus === '') {
                await Appl.Validator.setError('federalWithholdIncomeTaxStatus', 'Please select a filing status.');
            }
        }
        if (!Appl.Validator.isValid()) { return false; }
        const result = await this._service?.createOrUpdateFederalTaxWithholdingAsync(this.model.taxWithHolding.federalTaxWithHolding!);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.FederalTaxWithholding, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.TaxWithholding}/${Steps.StateTaxWithholding}`);
        return true;
    }

    @action public onStateTaxWithholdStatusChange(status: string, checked: boolean): void {

        if (this.model.taxWithHolding.stateTaxWithHolding) {
            if (status === "N" && checked) {
                this.model.taxWithHolding.stateTaxWithHolding.stateTaxWithhold = "N";
            } else if (status === "Y" && checked) {
                this.model.taxWithHolding.stateTaxWithHolding.stateTaxWithhold = "Y";

            } else if (status === "P" && checked) {
                this.model.taxWithHolding.stateTaxWithHolding.stateTaxWithhold = "P";
            }
            if ((status === "N" || status === "P") && checked) {
                if (this.model.taxWithHolding) {
                    if (this.model.taxWithHolding.stateTaxWithHolding) {
                        this.model.taxWithHolding.stateTaxWithHolding.stateWithholdIncomeTaxStatus = undefined;
                        this.model.taxWithHolding.stateTaxWithHolding.stateMarriedWithholdIncomeAllowance = undefined;
                        this.model.taxWithHolding.stateTaxWithHolding.stateSingleWithholdIncomeAllowance = undefined;
                        this.model.taxWithHolding.stateTaxWithHolding.stateHeadOfHouseHoldIncomeAllowance = undefined;
                        this.model.taxWithHolding.stateTaxWithHolding.stateAdditionalTaxToWithheldBasedOnTax = undefined;
                        this.model.taxWithHolding.stateTaxWithHolding.stateAdditionalTaxToWithheldBasedOnBenefitPayment = undefined;
                    }
                }
            }
            if ((status === "N" || status === "Y") && checked) {
                this.model.taxWithHolding.stateTaxWithHolding.stateAdditionalTaxToWithheldBasedOnBenefitPayment = undefined;
            }
        }
    }

    @action
    public async onStateTaxWithholdStepChangeAsync(): Promise<boolean> {
        Appl.Validator.init();
        if (this.model.taxWithHolding.stateTaxWithHolding) {
            if (this.model.taxWithHolding.stateTaxWithHolding.stateTaxWithhold == "Y") {
                if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === undefined ||
                    this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === "") {
                    await Appl.Validator.setError('withholdStateIncomeTaxStatus', 'Please select a filing status.');
                } else {
                    if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === "M") {
                        await Appl.Validator.validateNumber('Allowance', `stateMarriedWithholdIncomeAllowance`, this.model.taxWithHolding.stateTaxWithHolding?.stateMarriedWithholdIncomeAllowance, true);
                    } else if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === "S") {
                        await Appl.Validator.validateNumber('Allowance', `stateSingleWithholdIncomeAllowance`, this.model.taxWithHolding.stateTaxWithHolding?.stateSingleWithholdIncomeAllowance, true);
                    } else if (this.model.taxWithHolding.stateTaxWithHolding?.stateWithholdIncomeTaxStatus === "H") {
                        await Appl.Validator.validateNumber('Allowance', `stateHeadOfHouseHoldIncomeAllowance`, this.model.taxWithHolding.stateTaxWithHolding?.stateHeadOfHouseHoldIncomeAllowance, true);
                    }
                }
            } else if (this.model.taxWithHolding.stateTaxWithHolding.stateTaxWithhold == "P") {
                if (this.model.taxWithHolding.stateTaxWithHolding?.stateAdditionalTaxToWithheldBasedOnBenefitPayment === undefined
                    || this.model.taxWithHolding.stateTaxWithHolding?.stateAdditionalTaxToWithheldBasedOnBenefitPayment?.toString() === "") {
                    await Appl.Validator.validateNumber('Amount', `stateAdditionalTaxToWithheldBasedOnBenefitPayment`, this.model.taxWithHolding.stateTaxWithHolding?.stateAdditionalTaxToWithheldBasedOnBenefitPayment, true);
                }
            }
        }

        if (!Appl.Validator.isValid()) { return false; }
        const result = await this._service?.createOrUpdateStateTaxWithholdingAsync(this.model.taxWithHolding.stateTaxWithHolding!);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.StateTaxWithholding, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.TaxWithholding}/${Steps.DeclarationTaxWithholding}`);
        return true;
    }

    @action
    public async onTaxWithholdingMemberDeclarationCompletedAsync(agree: boolean): Promise<void> {
        const response = await this._service?.setTaxWithholdAggreedDeclarationStatusAsync(this.submissionId!, agree);
        if (response.success) {
            this.taxWithHoldingModelCompleted = true;
            this.setStepStatus(Steps.DeclarationTaxWithholding, StepStatus.Completed);
            await this.navigateToStepAsync(`${Steps.DirectDeposit}/${Steps.DirectDepositAuthorization}`);
        } else {
            Appl.Error.handle(response.error);
        }
    }

    @action public async onDirectDepositAccountInfoStepChangeAsync(): Promise<boolean> {
        this.model.directDeposit.submissionId = this.submissionId;
        Appl.Validator.init();
        await Appl.Validator.validateString('Institution Name', 'institutionName', this.model.directDeposit.institutionName, true, 100);
        await Appl.Validator.validateString('Name of Account Holder', 'accountHolderName', this.model.directDeposit.accountHolderName, true, 100);
        await Appl.Validator.validateString('9-Digit Routing Number', 'bankRoutingNumeric', this.model.directDeposit.bankRoutingNumeric, true, 9);
        await Appl.Validator.validateNumber('Account Number', 'bankAccountNumeric', this.model.directDeposit.bankAccountNumeric, true);
        await Appl.Validator.validateNumber('Confirm Account Number', 'confirmbankAccountNumeric', this.model.directDeposit.confirmbankAccountNumeric, true);
        if (this.model.directDeposit.bankAccountNumeric !== this.model.directDeposit.confirmbankAccountNumeric) {
            await Appl.Validator.setError('confirmbankAccountNumeric', 'Confirm account number is not matching with Account number.');
        }
        if (this.model.directDeposit.lovAccountTypeId === 0) {
            await Appl.Validator.setError('lovAccountTypeId', 'Please select a type of deposit account.');
        }
        if (this.model.directDeposit.bankRoutingNumeric?.length !== 9) {
            await Appl.Validator.setError('bankRoutingNumeric', 'Routing number should be 9 digit.');
        }
        if (!Appl.Validator.isValid()) { return false; }
        const result = await this._service?.createOrUpdateDirectDepositAsync(this.model.directDeposit);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.DirectDepositAuthorization, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.DirectDeposit}/${Steps.DirectDepositMemberDeclaration}`)
        return true;
    }

    @action
    public async onDirectDepositMemberDeclarationStepCompletedAsync(): Promise<boolean> {
        this.model.directDeposit.submissionId = this.submissionId;
        this.directDepositModelCompleted = true;
        this.setStepStatus(Steps.DirectDepositMemberDeclaration, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.TemporaryAnnuity}`);
        return true;
    }

    @action public async onTemporaryAnnuitySelectionStepAsync(): Promise<boolean> {
        const result = await this._service?.createOrUpdateTemporaryAnnuityAsync(this.model.temporaryAnnuity);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.TemporaryAnnuity, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.ServiceRetirementSignOff}`);
        return true;
    }


    @action
    public async onServiceRetirementSignOffStepCompletedAsync(): Promise<boolean> {
        const result = await this._service?.createOrUpdateServiceRetirementSignOffAsync(this.submissionId!, this.model.signOffModels);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.ServiceRetirementSignOff, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.ReviewApplication}`)
        return true;
    }

    @action onSignOffCheckboxChange = (e: any, model?: object, fieldId?: string) => {
        (model as any)[fieldId!] = e.target.checked;
        if (e.target.checked) {
            this.signOffCheckedItemsCount++;
        } else {
            this.signOffCheckedItemsCount--;
        }
    };

    @action public async onReviewApplicationStepChangeAsync(): Promise<boolean> {
        this.setStepStatus(Steps.ReviewApplication, StepStatus.Completed);
        return true;
    }

    @action public async updateSelfReviewStatusAsync(status: string): Promise<boolean> {
        await this._service?.updateSelfReviewStatusAsync(this.submissionId!, status);
        this.setStepStatus(Steps.ReviewApplication, StepStatus.Completed);
        await this.navigateToStepAsync(`${Steps.ReviewApplication}`);
        return true;
    }

    @action public async showUploadDialogAsync(): Promise<void> {
        this.uploadDialogVisible = true;
        this.activeDocument = { docTypeId: SupportedDocuments.BirthCertificate }
        this.activeDocument.docType = this.model.documentList?.find(o => o.key === 1)?.value;
    }

    @action public async hideUploadDialogAsync(): Promise<void> {
        this.uploadDialogVisible = false;
        this.activeDocument = { docTypeId: SupportedDocuments.BirthCertificate }
        this.activeDocument.docType = this.model.documentList?.find(o => o.key === 1)?.value;
    }

    @action public async onAddDocumentAsync(): Promise<void> {

        if (!this.model.uploadedDocuments) {
            this.model.uploadedDocuments = [];
        }
        if (!this.activeDocument.file) {
            this.showError('Please Add Documents');
            return;
        }
        this.model.uploadedDocuments.push(this.activeDocument);
        await this.hideUploadDialogAsync();
    }

    @action public async onRemoveDocumentAsync(document: DocumentsModel): Promise<void> {
        if (document.isNew) {
            this.model.uploadedDocuments = this.model.uploadedDocuments?.filter(item => item !== document)
        } else {
            this.activeDocument = document;
            this.confirmDialogOperation = 'DELETE_DOCUMENT';
            this.confirmDialogMessage = 'Are you sure you want to remove this document?';
            this.showConfirmDialog = true;
        }
    }

    @action public async downloadDocumentAsync(id: number, fileName: string): Promise<void> {
        const result = await this._service?.downloadAsync(`/retirement/Retiree/download/${this.submissionId}/${id}`);
        if (!result.success) {
            Appl.Error.handle(result.error);
        } else {
            Helper.downloadFile(result.data, fileName, '');
        }
    }

    @action public async getAppContentAsPdfAsync(submissionId: number, templateId: string, fileName: string): Promise<void> {
        const result = await this._service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${submissionId}/${templateId}`);
        if (!result.success) {
            Appl.Error.handle(result.error);
        } else {
            Helper.downloadFile(result.data, fileName, '');
        }
    }

    @action public getRequiredDocuments(): [] {
        let requiredDocuments = [];
        if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Married) {
            let marriageCert = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.MarriageRegistrationCertificate);
            requiredDocuments.push({ id: 1, name: 'Marriage Certificate', uploaded: marriageCert !== undefined });
            let spouseBirthCert = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate);
            requiredDocuments.push({ id: 2, name: 'Birth Certificate - Spouse', uploaded: spouseBirthCert !== undefined });
        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.RegistedDomesticPartner) {
            //require certificate of domestic partnership and partner's birth certificate
            let partnerbirthCert = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate);
            requiredDocuments.push({ id: 3, name: 'Birth Certificate - Partner', uploaded: partnerbirthCert !== undefined });
            let domasticPartner = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerSsn);
            requiredDocuments.push({ id: 4, name: 'Certificate of Domestic Partnership', uploaded: domasticPartner !== undefined });
        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Divorced) {
            //require DRO
            let dro = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.CourtEndorsedDomesticRelationsOrder);
            requiredDocuments.push({ id: 5, name: 'A Court Endorsed Domestic Relations Order (DRO)', uploaded: dro !== undefined });
        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Widowed) {
            //require spouse's death certificate
            let dthCert = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDeathCertificate);
            requiredDocuments.push({ id: 6, name: 'Death Certificate - Spouse', uploaded: dthCert !== undefined });
        }

        if (this.model.directDeposit.lovAccountTypeId === AccountType.CheckingAccount) {
            let voidCheck = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.VoidedCheck);
            requiredDocuments.push({ id: 7, name: 'Direct Deposit Authorization - Voided Check', uploaded: voidCheck !== undefined });
        } else if (this.model.directDeposit.lovAccountTypeId === AccountType.SavingsAccount) {
            let bankLetter = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.CertifiedBankLetterOrSavingsAccountStatement);
            requiredDocuments.push({ id: 8, name: 'Certified bank letter or savings account statement', uploaded: bankLetter !== undefined });
        }

        if (this.model.signOffModels.find(o => o.lovServiceSignOffId === 7)?.active) {//Temporary Annuity
            let tempAnuity = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.TemporaryAnnuitiesEstimateFromSocialSecurityOffice);
            requiredDocuments.push({ id: 9, name: 'Temporary annuities estimate from Social Security office', uploaded: tempAnuity !== undefined });
        }

        if (this.model.beneficiaryDesignation?.beneficiaries && this.model.beneficiaryDesignation?.beneficiaries.length > 0) {
            let num = 9;
            for (let i = 0; i < this.model.beneficiaryDesignation?.beneficiaries.length; i++) {
                let ben = this.model.beneficiaryDesignation?.beneficiaries[i];
                let benUploaded = this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate);
                requiredDocuments.push({ id: num + 1, name: `Beneficiaries Birth Certificates - ${ben.beneFirstName} ${ben.beneLastName}`, uploaded: benUploaded !== undefined });
            }
        }
        return requiredDocuments as [];
    }

    @action public async onSubmitRequiredDocumentsAsync(): Promise<void> {
        this.documentUploadModelCompleted = false;
        let message = "";
        if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Married) {
            //Marriage Certificate & spouse birth certificate
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.MarriageRegistrationCertificate)) {
                message += "<li>Marriage Certificate is required.</li>"
            }
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate)) {
                message += "<li>Spouse Birth Certificate is required.</li>"
            }

        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.RegistedDomesticPartner) {
            //require certificate of domestic partnership and partner's birth certificate
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate)) {
                message += "<li>Partner's Birth Certificate is required.</li>"
            }
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerSsn)) {
                message += "<li>Certificate of Domestic Partnership is required.</li>"
            }
        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Divorced) {
            //require DRO
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.CourtEndorsedDomesticRelationsOrder)) {
                message += "<li>A Court Endorsed Domestic Relations Order (DRO) is required.</li>"
            }
        } else if (this.model.personalInformation.lovPersonalStatusId === PersonalStatus.Widowed) {
            //require spouse's death certificate
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDeathCertificate)) {
                message += "<li>Spouse's Death Certificate is required.</li>"
            }
        }
        if (this.model.directDeposit.lovAccountTypeId === AccountType.CheckingAccount) {
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.VoidedCheck)) {
                message += "<li>Voided Check is required.</li>"
            }
        } else if (this.model.directDeposit.lovAccountTypeId === AccountType.SavingsAccount) {
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.CertifiedBankLetterOrSavingsAccountStatement)) {
                message += "<li>Certified bank letter or savings account statement is required.</li>"
            }
        }
        if (this.model.signOffModels.find(o => o.lovServiceSignOffId === 7)?.active) {//Temporary Annuity
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.TemporaryAnnuitiesEstimateFromSocialSecurityOffice)) {
                message += "<li>Temporary annuities estimate from Social Security office is required.</li>"
            }
        }
        if (this.model.beneficiaryDesignation?.beneficiaries && this.model.beneficiaryDesignation?.beneficiaries.length > 0) {
            if (!this.model.uploadedDocuments.find(o => o.docTypeId === SupportedDocuments.SpouseDomesticPartnerOtherBeneficiaryBirthCertificate)) {
                message += "<li>Beneficiaries Birth Certificates are required.</li>"
            }
        }
        if (message !== "") {
            message = `Below documents are required.<ul class="list-disc ml-4 pb-6">${message}</ul>`
            Appl.MessageBox.show(message, "Upload Documents")
            return;
        }
        let duplicates = this.findDuplicates(this.model.uploadedDocuments)
        if (duplicates.length > 0) {
            Appl.MessageBox.show(`${duplicates.length} documnets has same name. Please use different documents`, "Upload Documents")
            return;
        }
        if (this.model.uploadedDocuments.find(o => o.isNew)) {
            let result = await this._service?.uploadAsync(`/retirement/Retiree/createOrUpdateRequiredDocuments/${this.submissionId}`, this.model.uploadedDocuments);
            if (!result.success) {
                Appl.Error.handle(result.error);
                return;
            } else {
                result = await this._service?.getSubmittedDocumentsAsync(this.submissionId!);
                if (result.success) {
                    runInAction(() => {
                        this.model.uploadedDocuments = result.data;
                    });
                }
            }
        }
        this.documentUploadModelCompleted = true;
        this.setStepStatus(Steps.UploadDocuments, StepStatus.Completed);
    };

    private findDuplicates(items: any) {
        let sortedArr = items.slice().sort();
        let results = [];
        for (let i = 0; i < sortedArr.length - 1; i++) {
            if (sortedArr[i + 1] == sortedArr[i]) {
                results.push(sortedArr[i]);
            }
        }
        return results;
    }

    @action
    public async submitApplicationAsync(): Promise<boolean> {
        Appl.Validator.init();
        await Appl.Validator.validateString('Full Name', 'fullName', this.model.personalInformation.fullName, true, 200);
        if (!Appl.Validator.isValid()) { return false; }
        const result = await this._service?.submitApplication(this.submissionId!, this.model.personalInformation.fullName!);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return false;
        }
        this.setStepStatus(Steps.SubmitApplication, StepStatus.Completed);
        this.navigate(`/sra/retiree/afr-status/${this.memberId}`)
        return true;
    }

    public async getSubmissionStatus(memberId: string): Promise<void> {
        const result = await this._service?.getSubmissionByMemberIdAsync(memberId, true);
        if (!result.success) {
            Appl.Error.handle(result.error);
            return;
        } else {
            runInAction(() => {
                this.submission = result.data
            });
        }
    }

    @action public async showPrintDialog(submissionId: number): Promise<void> {
        const result = await this._service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${submissionId}/6105b`);
        if (result.success) {
            this.afrFile = result.data
        } else {
            Appl.Error.handle(result.error);
        }
        this.printDialogVisible = true;
    }

    @action public async hidePrintDialog(): Promise<void> {
        this.printDialogVisible = false;
    }

    @action public async searchAddress(filter?: string): Promise<void> {
        const result = await this.lovService.getListOfValuesAsync('address-lookup', filter);
        runInAction(async () => {
            if (result.success) {
                this.addressListItems = result.data;
            } else {
                Appl.Error.handle(result.error);
            }

        });
    }

    @action public async navigateToStepAsync(url: string): Promise<boolean> {
        if (this.navigatedFromReviewScreen) {
            this.navigatedFromReviewScreen = false;
            this.navigate(`/sra/retiree/application/${this.memberId}/print/${this.submissionId}`);
        } else {
            this.navigate(`/sra/retiree/application/${this.memberId}/${url}`);
        }
        return true;
    }

    @action public async navigateFromReviewStepAsync(e: any, url: string): Promise<void> {
        e.preventDefault();
        Appl.MessageBox.hide();
        this.navigatedFromReviewScreen = true;
        await this._service?.updateSelfReviewStatusAsync(this.submissionId!, "N");
        this.navigate(url);
    }

    // @action public async onSickLeaveStepChangeAsync(): Promise<boolean> {
    //     this.model.stepStatus.sickLeave = StepStatus.Completed;
    //     await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
    //     return true;
    // }

    // @action
    // public async createOrUpdateAccruedSickLeaveAsync(): Promise<boolean> {
    //     const result = await this._service?.createOrUpdateAccruedSickLeavesync(this.model.accruedSickLeave);
    //     if (!result.success) {
    //         Appl.Error.handle(result.error);
    //         return false;
    //     }
    //     this.accruedSickLeaveModelCompleted = true;
    //     this.model.stepStatus.dispositionOfAccruedSickLeave = StepStatus.Completed;
    //     await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
    //     return true;
    // }

    // public onSickLeaveCreditChange(e: any, fieldName: string): void {
    //     if (fieldName === 'sickLeaveCreditN') {
    //         this.model.accruedSickLeave.sickLeaveCredit = !e.target.checked;
    //         this.model.accruedSickLeave.sickLeaveCreditN = e.target.checked;
    //     } else {
    //         this.model.accruedSickLeave.sickLeaveCredit = e.target.checked;
    //         this.model.accruedSickLeave.sickLeaveCreditN = !e.target.checked;
    //     }
    //     if (!this.model.accruedSickLeave.sickLeaveCredit) {
    //         this.model.accruedSickLeave.sickLeaveHours = 0;
    //     }
    // }

    // @action public async onScreaMembershipStepChangeAsync(intersted: boolean): Promise<boolean> {
    //     this.model.stepStatus.screaMembership = StepStatus.Completed;
    //     await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
    //     if (intersted) {
    //         this.navigate(`/sra/retiree/application/${this.memberId}/${Steps.ScreaMembership}/${Steps.MembershipApplication}`)
    //     } else {
    //         this.screaMembershipModelCompleted = true; this.navigate(`/sra/retiree/application/${this.memberId}/${Steps.ScreaMembership}/${Steps.MembershipApplication}`)
    //     }
    //     return true;
    // }

    // @action
    // public async createOrUpdateScreaMembershipAsync(): Promise<boolean> {

    //     Appl.Validator.init();
    //     if (this.model.screaMembership.associateMembership) {
    //         await Appl.Validator.validateString('Associate Member Type', 'associateMbrType', this.model.screaMembership.associateMbrType, true, 10);
    //         await Appl.Validator.validateString('Associate Member Name', 'associateMbrName', this.model.screaMembership.associateMbrName, true, 100);
    //     }
    //     if (!Appl.Validator.isValid()) { return false; }
    //     this.model.screaMembership.submissionId = this.submissionId;
    //     const result = await this._service?.createOrUpdateScreaMembershipAsync(this.model.screaMembership);
    //     if (!result.success) {
    //         Appl.Error.handle(result.error);
    //         return false;
    //     }
    //     this.screaMembershipModelCompleted = true;
    //     this.model.stepStatus.membershipApplication = StepStatus.Completed;
    //     await this._service?.updateSubmissionStepStatus(this.submissionId!, this.model.stepStatus);
    //     return true;
    // }


}