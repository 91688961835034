import { ListItem } from "../../../../../core/services/BaseService";
import { DocumentsModel } from "../../../../../core/components/UploadUtility";
import { StepStatus } from "./SidebarFormNavigation";

export enum Steps {
    GettingStarted = 'getting-started',
    RetirementDate = 'retirement-date',
    SelectDate = 'select-date',
    YourInformation = 'your-information',//Root
    PersonalInformation = 'personal-information',
    AddressInformation = 'address-information',
    ContactInformation = 'contact-information',
    PersonalStatus = 'personal-status',
    OptionSelection = 'option-selection',//Root
    OptionSelectionSelectYourOption = 'select-your-option',
    OptionSelectionMemberDeclaration = 'member-declaration',
    BeneficiaryDesignation = 'beneficiary-designation',//Root
    BeneficiaryImportantInformation = 'important-information',
    AddBeneficiary = 'add-beneficiary',
    BeneficiaryMemberDeclaration = 'beneficiary-member-declaration',
    TaxWithholding = 'tax-withholding',//Root
    FederalTaxWithholding = 'federal-tax-withholding',
    StateTaxWithholding = 'state-tax-withholding',
    DeclarationTaxWithholding = 'declaration-tax-withholding',
    DirectDeposit = 'direct-deposit',//Root
    DirectDepositAuthorization = 'direct-deposit-authorization',
    DirectDepositMemberDeclaration = 'direct-deposit-member-declaration',
    TemporaryAnnuity = 'temporary-annuity',
    ServiceRetirementSignOff = 'service-retirement-sign-off',
    ReviewApplication = 'review-application',
    UploadDocuments = 'upload-documents',
    SubmitApplication = 'submit-application'
}

export interface StepStatuses {
    gettingStarted?: string;
    selectDate?: string;
    yourInformation: string;
    personalInformation?: string;
    addressInformation?: string;
    contactInformation?: string;
    personalStatus?: string;
    optionSelection?: string;
    optionSelectionSelectYourOption?: string;
    optionSelectionMemberDeclaration?: string;
    beneficiaryDesignation?: string;
    beneficiaryImportantInformation?: string;
    addBeneficiary?: string;
    beneficiaryMemberDeclaration?: string;
    taxWithholding?: string;
    federalTaxWithholding?: string;
    stateTaxWithholding?: string;
    declarationTaxWithholding?: string;
    directDeposit?: string;
    directDepositAuthorization?: string;
    directDepositMemberDeclaration?: string;
    temporaryAnnuity?: string
    serviceRetirementSignOff?: string;
    reviewApplication?: string;
    uploadDocuments?: string;
    submitApplication?: string;
}

export interface StepInfoModel {
    slug: string,
    status: StepStatus;
    nextButtonLabel: string;
    cancelButtonLabel: string;
    hideNextButton: boolean,
    hideCancelButton: boolean
}
export interface SubmissionModel {
    submissionId?: number;
    submissionDate?: Date;
    retirementDate?: Date;
    lovSubmissionStatusId?: number
    submissionStatusDesc?: string;
    submissionType?: string;
}

export interface AppForRetirementModel {
    submissionId: number;
    submissionStatusId: number;
    // stepStatus: StepStatuses;
    personalInformation: PersonalInformationModel,
    optionSelection: OptionSelectionModel,
    temporaryAnnuity: TemporaryAnnuityModel,
    lovOptionSelections?: Array<LovOptionSelectionModel>,
    beneficiaryDesignation: BeneficiaryDesignationModel,
    taxWithHolding: TaxWithHoldingModel,
    directDeposit: DirectDepositModel;
    accruedSickLeave: AccruedSickLeaveModel;
    screaMembership: ScreaMembershipModel;
    signOffModels: Array<ServiceRetirementSignOffModel>
    uploadedDocuments: Array<DocumentsModel>,
    phoneTypeListItems?: Array<ListItem>,
    personalStatusListItems?: Array<ListItem>,
    beneficiaryRelationListItems?: Array<ListItem>,
    withholdFederalIncomeTaxStatusListItems?: Array<ListItem>,
    withholdStateIncomeTaxStatusListItems?: Array<ListItem>,
    typeOfDepositListItems?: Array<ListItem>,
    associateMemberTypeListItems?: Array<ListItem>,
    documentList?: Array<ListItem>
    // applicationReviewed?: boolean
}

export interface PersonalInformationModel {
    submissionId: number;
    retirementDate?: Date;

    personalInfoVerified?: string
    firstName?: string;
    lastName?: string;
    middleName?: string;
    birthDate?: string;
    birthMonth?: number;
    birthDay?: number;
    birthYear?: number;
    ssn?: string;
    ssnMasked?: string;
    employer?: string;
    pin?: number;
    department?: string;
    retirementEffMonth?: number;
    retirementEffDay?: number;
    retirementEffYear?: number;
    retirementEffDate?: string;// | Date;

    email?: string;
    phone?: string;
    phoneType?: string;
    alternatePhone?: string;
    alternatePhoneType?: string;
    lovMembershipId?: number;
    membershipCd?: string;
    membershipDesc?: string;

    lovPersonalStatusId?: number;
    personalStatusCd?: string;
    lovPersonalStatusDesc?: string;

    validateAddress?: boolean
    homeRawAddress?: string;
    marriageDate?: string & Date | null;
    partnershipDate?: string & Date | null;
    homeCountry?: string;

    mailingRawAddress?: string;
    mailingCountry?: string;
    appSelfReviewed?: string;
    fullName?: string;
}

export interface LovOptionSelectionModel {
    id?: number;
    name?: string;
    description: string;
    selected: boolean;
}

export interface OptionSelectionModel {
    submissionId?: number;
    afrApplicationId?: number;
    beneficiaryPaymentOptionId?: number;
    beneficiaryPaymentOptionDeclaration: boolean;
}

export interface TemporaryAnnuityModel {
    submissionId?: number;
    afrApplicationId?: number;
    tempAnnuitySelection?: string;
}

export interface BeneficiaryDesignationModel {
    submissionId?: number;
    afrMbrAffidavitId?: number;
    afrDecleration: boolean;
    beneficiaries?: Array<BeneficiaryModel>;
}

export interface BeneficiaryModel {
    afrBeneId?: number;
    afrMbrAffidavitId?: number;
    beneFirstName?: string;
    beneMiddleName?: string;
    beneLastName?: string;
    beneOtherName?: string;
    beneSsn?: string;
    beneTin?: string;
    beneBirthDate?: string;
    beneBirthDateYear?: number;
    beneBirthDateMonth?: number;
    beneBirthDateDay?: number;
    lovBeneRelationshipId?: number;
    percentage?: number;
    beneRawAddress?: string;
    beneCountry?: string
}

export interface TaxWithHoldingModel {
    submissionId?: number;
    afrTaxWithholdId?: number;
    taxwithholdDecleration: boolean;
    federalTaxWithHolding?: FederalTaxWithHoldingModel;
    stateTaxWithHolding?: StateTaxWithHoldingModel;
}

export interface FederalTaxWithHoldingModel {
    submissionId?: number;
    afrTaxWithholdId?: number;
    federalTaxWithhold: string;
    federalWithholdIncomeTaxStatus?: string;
    federalClaimsDependant?: number;
    federalDeductionsClaimed?: number;
    federalAdditionalWithold?: number;
    federalIncomeNotFromJob?: number;
}

export interface StateTaxWithHoldingModel {
    submissionId?: number;
    afrTaxWithholdId?: number;
    stateTaxWithhold: string;
    stateWithholdIncomeTaxStatus?: string;
    stateMarriedWithholdIncomeAllowance?: number;
    stateSingleWithholdIncomeAllowance?: number;
    stateHeadOfHouseHoldIncomeAllowance?: number;
    stateAdditionalTaxToWithheldBasedOnTax?: number;
    stateAdditionalTaxToWithheldBasedOnBenefitPayment?: number;
}

export interface DirectDepositModel {
    submissionId?: number;
    afrDirectDepositId?: number;
    institutionName?: string;
    accountHolderName?: string;
    lovAccountTypeId?: number;
    bankRoutingNumeric?: string;
    bankAccountNumeric?: string;
    confirmbankAccountNumeric?: string;
    directDepositConsent: boolean
}

export interface AccruedSickLeaveModel {
    afrAccruedSickLeaveId?: number;
    submissionId?: number;
    sickLeaveCredit: boolean;
    sickLeaveCreditN: boolean;
    sickLeaveHours?: number;
}

export interface ScreaMembershipModel {
    afrAccruedSickLeaveId?: number;
    submissionId?: number;
    regularMembership: boolean;
    associateMembership: boolean;
    acceptMembershipConsent: boolean;
    associateMbrType?: string;
    associateMbrName?: string;
}

export interface ServiceRetirementSignOffModel {
    submissionId?: number;
    afrServiceSignOffId?: number;
    active: boolean
    lovServiceSignOffId?: number;
    lovServiceSignOffCd?: string;
    lovServiceSignOffDesc?: string
}


