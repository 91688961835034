import { Appl } from "../../../../core/Appl";
import { LovService } from "./LovService";
import LovListViewModel from "./list/LovListViewModel";
import LovViewModel from "./addEdit/LovViewModel";


export default class LovModule {
    public static init(): void {
        Appl.Services.add('ILovService', LovService);
        Appl.Services.add('LovListViewModel', LovListViewModel);
        Appl.Services.add('LovViewModel', LovViewModel);

    }
}