import { Appl } from '../../../core/Appl';
import { AccountService } from './AccountService';
import LoginViewModel from './login/LoginViewModel';
import ProfileViewModel from './profile/ProfileViewModel';

export default class AccountModule {
  public static init(): void {
    Appl.Services.add('IAccountService', AccountService);
    Appl.Services.add('LoginViewModel', LoginViewModel);
    Appl.Services.add('ProfileViewModel', ProfileViewModel);
  }
}
