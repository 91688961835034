import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Appl } from '../Appl';

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest as any, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

const onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  Appl.Spinner.show();
  const token = Appl.User?.accessToken;
  if (token) {
    const newToken = token.replace(/['"]+/g, '');
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${newToken}`;
  }
  config.data = cleanJSONForPost(config.data);
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  Appl.Spinner.hide();
  response.data = cleanJSONForGet(response.data);
  return response;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  Appl.Spinner.hide();
  return Promise.reject(error);
};

function cleanJSONForGet(json: any) {
  for (const key in json) {
    // let type = typeof json[key];
    //if (json[key] === undefined || json[key] === null) {
    if (json[key] === null) {
      json[key] = '';
    } else if (typeof json[key] === 'object') {
      json[key] = cleanJSONForGet(json[key]);
    }
  }
  return json;
}

function cleanJSONForPost(json: any) {
  for (const key in json) {
    const type = typeof json[key];
    if (json[key] === '') {
      json[key] = undefined;
    } else if (type === 'object') {
      try {
        json[key] = cleanJSONForPost(json[key]);
      } catch (e) { }
    }
  }
  return json;
}

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  Appl.Spinner.hide();
  error.message = await handleResponseError(error);
  return Promise.reject(error);
};

const handleResponseError = async (error: any): Promise<string> => {
  let message = error.message;
  if (error.response) {
    //const data = await error.response.data;
    const isJsonBlob = (data1: any) => data1 instanceof Blob && data1.type === "application/json";
    const responseData = isJsonBlob(error.response?.data) ? await (error.response?.data)?.text() : error.response?.data || {};
    const data = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
    if (data) {
      if (data.title) {
        message = `${data.title}`;
      }
      try {
        if (data.errors) {
          for (const err of data.errors) {
            message = `${message}. ${err.Message}, Trace Id:${err.TraceId}`;
          }
        } else {
          if (Array.isArray(data)) {
            for (const err of data) {
              if (err.Stack) {
                if (err.Stack.includes('ValidationException')) {
                  message = `${err.Message}`;
                }
              } else {
                message = `${message}. ${err.Message}, Trace Id:${err.TraceId}`;
              }
            }
          } else {
            message = `${message}. ${data}`;
          }
        }
      } catch {
        message = JSON.stringify(data.errors);
      }
    }
  }
  return message;
};
