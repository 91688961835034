import { observer } from 'mobx-react';
import { Button, Input, InputType, View, ViewHeader, InputOptionBox, InputCalendar } from '../../../../../core/components/Index'
import useAsyncEffect from 'use-async-effect';
import { useNavigate, useParams } from 'react-router-dom';
import { Appl } from '../../../../../core/Appl';
import ChangeOfAddressViewModel from './ChangeOfAddressViewModel';
import { CheckboxChangeEvent } from 'primereact/checkbox';

const ChangeOfAddressView = observer(() => {
  const vm = Appl.Services.get<ChangeOfAddressViewModel>('ChangeOfAddressViewModel');
  vm.navigate = useNavigate();
  let { id } = useParams();
  useAsyncEffect(async () => {
    await vm.initAsync(id as unknown as number);
  }, []);

  return (
    <>
      <View
        id="ChangeOfAddressView"
        pageTitle={vm.pageTitle}
        authorized={Appl.User.isAuthenticated}
        showMenuBar={false}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" backUrl="" />
        <div className="space-y-6 mt-4">
          <h2 className="text-22px font-semibold mb-6 text-black">1. Membership Status</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-6">
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <InputOptionBox
                  inputId="retired"
                  name="retired"
                  label="Retired"
                  className="mt-1"
                  checked={vm.model.retired!}
                  onChange={(e: CheckboxChangeEvent) => vm.model.retired = e.checked!}
                />
              </div>
              <div>
                <InputOptionBox
                  inputId="deferred"
                  name="deferred"
                  label="Deferred"
                  className="mt-1"
                  checked={vm.model.deferred!}
                  onChange={(e: CheckboxChangeEvent) => vm.model.deferred = e.checked!}
                />
              </div>
            </div>
          </div>
          <h2>NOTE: ALL ACTIVE MEMBER ADDRESS CHANGES ARE PROCESSED THROUGH YOUR EMPLOYER’S PERSONNEL/PAYROLL DEPARTMENT.</h2>
          <hr />
          <h2 className="text-22px font-semibold mb-6 text-black">2. Personal Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-6">
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <Input
                  id="fullName"
                  value={vm.model.fullName}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onInputChange(e, 'fullName', vm.model);
                  }}
                  label="Full Name"
                  required={true}
                  maxLength={200}
                />
              </div>
              <div>
                <Input
                  id="ssn"
                  value={vm.model?.ssn}
                  label="SSN (last 4 digits only)"
                  inputType={InputType.Text}
                  onChange={(e: any) => vm.onInputChange(e, 'ssn', vm.model)}
                  maxLength={4}
                  required={true}
                />
              </div>
            </div>
          </div>
          <hr />
          <h2 className="text-22px font-semibold mb-6 text-black">3. Address Information</h2>
          <h4 className="text-18px font-semibold mb-6 text-black">Mailing Address</h4>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-6">
            <div>
              <Input
                id="mailingAddress"
                value={vm.model.mailingAddress}
                inputType={InputType.Text}
                onChange={(e: any) => {
                  vm.onAddressInfoChange(e, 'mailingAddress');
                }}
                label="Street Name"
                required={true}
                maxLength={200}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-6">
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <Input
                  id="mailingCity"
                  value={vm.model?.mailingCity}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onAddressInfoChange(e, 'mailingCity');
                  }}
                  label="City"
                  required={true}
                  maxLength={100}
                />
              </div>
              <div>
                <Input
                  id="mailingState"
                  value={vm.model?.mailingState}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onAddressInfoChange(e, 'mailingState');
                  }}
                  label="State"
                  required={true}
                  maxLength={50}
                />
              </div>
              <div>
                <Input
                  id="mailingZip"
                  value={vm.model?.mailingZip}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onAddressInfoChange(e, 'mailingZip');
                  }}
                  label="Zip"
                  maxLength={5}
                  required={true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h4 className="text-18px font-semibold mb-6 text-black">Home Address</h4>
            <div>
              <InputOptionBox
                inputId="sameAsMailingAddressSelected"
                label="Same as Mailing Address"
                className="mt-1"
                checked={vm.sameAsMailingAddressSelected}
                onChange={(e: CheckboxChangeEvent) => vm.onSameAsMailingAddressChange(e)}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 mb-3">
            <div>
              <Input
                id="homeAddress"
                value={vm.model.homeAddress}
                inputType={InputType.Text}
                onChange={(e: any) => {
                  vm.onAddressInfoChange(e, 'homeAddress');
                }}
                label="Street Name"
                required={true}
                maxLength={200}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-6">
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <Input
                  id="homeCity"
                  value={vm.model?.homeCity}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onAddressInfoChange(e, 'homeCity');
                  }}
                  label="City"
                  required={true}
                  maxLength={100}
                />
              </div>
              <div>
                <Input
                  id="homeState"
                  value={vm.model?.homeState}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onAddressInfoChange(e, 'homeState');
                  }}
                  label="State"
                  required={true}
                  maxLength={50}
                />
              </div>
              <div>
                <Input
                  id="homeZip"
                  value={vm.model?.homeZip}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onAddressInfoChange(e, 'homeZip');
                  }}
                  label="Zip"
                  maxLength={5}
                  required={true}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 mb-3">
            <div>
              <InputCalendar
                id="changeEffDate"
                value={vm.model?.changeEffDate!}
                label="When would you like the above change(s) to take effect? (MM/DD/YYYY)"
                onChange={(e: any) => {
                  vm.onInputChange(e, 'changeEffDate', vm.model);
                }}
                required={true}
                minDate={new Date()}
              />
            </div>
          </div>
          <hr />
          <h2 className="text-22px font-semibold mb-6 text-black">4. Member Approval of Required Changes</h2>
          <InputOptionBox
            inputId="iAgree"
            label="I confirm that the address provided above is accurate and reflects my current residence. I understand that this information will be used to update my records and ensure proper delivery of correspondence."
            className="mt-1"
            checked={vm.model.iAgree!}
            onChange={(e: CheckboxChangeEvent) => vm.model.iAgree = e.checked!}
          />
          <hr />
          <div className="flex items-center justify-center gap-x-6 my-6">
            <Button title="Submit"
              disabled={!vm.model.iAgree}
              onClick={async () => await vm.saveAddressAsync()} />
            <Button title="Cancel" severity="secondary" onClick={() => vm.navigate(-1)} />
          </div>
        </div>
      </View >
    </>
  );
});

export default ChangeOfAddressView;
