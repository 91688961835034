import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { InputErrors } from './InputErrors';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Icon } from '../Icon';
import { Appl } from '../../../core/Appl';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import './Inputs.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export enum InputType {
  Text = 'text',
  Password = 'password',
  Search = 'search',
  Number = 'number',
  Email = 'email',
  Multiline = 'multiline',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Color = 'color',
  Date = 'date',
  Time = 'time',
  Week = 'week',
  Month = 'month',
  DateTime = 'datetime-local',
  File = 'file',
  Hidden = 'hidden',
  Range = 'range',
  Phone = 'tel',
  Url = 'url',
  Masked = 'masked',
  Secret = 'secret'
}

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  inputType?: InputType;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  required?: boolean;
  rows?: number;
  helpText?: string;
  className?: string | undefined;
  readOnly?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  minNumber?: number;
  maxNumber?: number;
  minRange?: number;
  maxRange?: number;
  maxLength?: number;
  numberStep?: number;
  pattern?: string;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  returnKeyType?: 'done' | 'go' | 'next' | 'search' | 'send';
  keyboardAppearance?: 'default' | 'light' | 'dark' | undefined;
  iconName?: string;
  suffixid?: string;
  mask?: string;
  maskChar?: string;
  maskedV?: boolean; //allow masked input with toggle
}

export const Input: React.FC<InputProps> = observer(({
  inputType = InputType.Text,
  className = '',
  ...props
}) => {
  const [isMasked, setIsMasked] = useState(false);

  const toggleMask = () => {
    setIsMasked((prev) => !prev);
  };


  const readOnlyClasses = '[&>input]:!bg-gray-100 [&>input]:cursor-not-allowed';
  const renderLabel = () => {
    if (props.label && !props.hidden) {
      return (
        <label htmlFor={props.id} className='font-bold text-15px text-black mb-1 block'>
          {props.label}
          {props.required && <span style={{ color: 'red' }}> * </span>}
          {props.helpText && <Icon name="question-circle" color="black" size={16} />}
        </label>
      );
    }
    return null;
  };

  return (
    <>
      {renderLabel()}
      {props.disabled ? (
        <div >{props.value?.toString()}</div>
      ) : (
        <>
          {inputType === InputType.Number ? (
            <>
              {!props.hidden && <InputNumber
                id={props.id}
                className={`${className} ${props.readOnly && readOnlyClasses}`}
                // @ts-ignore: Unreachable code error
                value={parseInt(props.value)}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                autoCapitalize={props.autoCapitalize}
                autoComplete="off"
                maxLength={props.maxLength}
                hidden={props.hidden}
                style={props.style}
                data-val-field-label={`${props.id}-feedback`}
                aria-label={props.label}
                readOnly={props.readOnly}
                format={false}
                {...props}
              />}
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}

            </>
          ) : (inputType === InputType.Phone) ? (
            <>
              {!props.hidden &&
                <PhoneInput
                  inputProps={{
                    id: props.id,
                    style: props.style,
                    hidden: props.hidden,
                    focus: props.autoFocus,
                    maxLength: props.maxLength,
                    readOnly: props.readOnly
                  }}
                  country={'us'}
                  inputClass={`rounded-lg m-0 mt-1 flex !h-10 !w-full !rounded-lg !border !border-neutral-300 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-primary-500 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-300 ${props.readOnly && '!bg-gray-100 !cursor-not-allowed'}`}
                  // @ts-ignore: Unreachable code error
                  value={props.value ? props.value : ''}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  enableSearch={true}
                  copyNumbersOnly={false}
                  enableLongNumbers={false}
                  disableDropdown={props.readOnly}
                  {...props}
                />
              }
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}
            </>
          ) : inputType === InputType.Secret ? (
            <>
              <div className="flex items-center space-x-6">
                <div>
                  <InputText
                    id={props.id}
                    type={!isMasked ? 'password' : 'text'}
                    className={`${className} ${props.readOnly && '!bg-gray-100 !cursor-not-allowed'} !max-w-[120px]`}
                    // @ts-ignore: Unreachable code error
                    value={props.value ? String(props.value) : ''}
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus}
                    maxLength={props.maxLength}
                    hidden={props.hidden}
                    style={props.style}
                    data-val-field-label={`${props.id}-feedback`}
                    aria-label={props.label}
                    readOnly={props.readOnly}
                    {...props}
                  />
                </div>
                <div className='relative h-full flex mt-1'>
                  {!isMasked ? <svg viewBox="0 0 14 11" fill="none" className="absolute z-10 top-[7px] left-[9px] size-[14px]">
                    <path d="M0.848858 0.109689C0.621362 -0.0664793 0.291055 -0.0256599 0.111683 0.197773C-0.0676884 0.421205 -0.0261266 0.745612 0.201369 0.92178L13.1511 10.8903C13.3786 11.0665 13.7089 11.0257 13.8883 10.8022C14.0677 10.5788 14.0261 10.2544 13.7986 10.0782L11.4974 8.30795C12.3637 7.4357 12.9499 6.45818 13.2452 5.76425C13.3174 5.59453 13.3174 5.40547 13.2452 5.23575C12.9193 4.46877 12.2346 3.35161 11.2109 2.41921C10.1828 1.47821 8.76747 0.687606 7 0.687606C5.50815 0.687606 4.26567 1.25263 3.29663 1.99383L0.848858 0.109689ZM4.88035 3.21196C5.43815 2.71139 6.18408 2.40632 7 2.40632C8.73903 2.40632 10.1499 3.79203 10.1499 5.5C10.1499 6.03495 10.0121 6.53767 9.76933 6.97594L8.92497 6.32713C9.10871 5.91249 9.15684 5.4377 9.02996 4.9672C8.78716 4.07562 7.98436 3.47622 7.09187 3.43969C6.965 3.4354 6.89063 3.57075 6.93 3.69106C6.97594 3.82855 7.00219 3.97464 7.00219 4.12718C7.00219 4.34631 6.94969 4.55256 6.85781 4.73517L4.88254 3.21411L4.88035 3.21196ZM8.15935 8.37669C7.80061 8.51634 7.40906 8.59368 7 8.59368C5.26097 8.59368 3.85006 7.20797 3.85006 5.5C3.85006 5.35176 3.86099 5.20782 3.88068 5.06603L1.8179 3.46977C1.31916 4.10784 0.962606 4.74377 0.754797 5.23575C0.682611 5.40547 0.682611 5.59453 0.754797 5.76425C1.08073 6.53123 1.7654 7.64839 2.78914 8.58079C3.81724 9.52179 5.23253 10.3124 7 10.3124C8.04561 10.3124 8.96653 10.0353 9.76058 9.61417L8.15935 8.37669Z" fill="black" />
                  </svg>

                    : <svg viewBox="0 0 14 11" fill="none" className="absolute z-10 top-[7px] right-[5px] size-[14px]">
                      <path d="M6.99879 0C5.03507 0 3.46263 0.903571 2.31794 1.97902C1.18054 3.04464 0.419842 4.32143 0.060151 5.19799C-0.0200503 5.39196 -0.0200503 5.60804 0.060151 5.80201C0.419842 6.67857 1.18054 7.95536 2.31794 9.02098C3.46263 10.0964 5.03507 11 6.99879 11C8.9625 11 10.5349 10.0964 11.6796 9.02098C12.817 7.9529 13.5777 6.67857 13.9398 5.80201C14.0201 5.60804 14.0201 5.39196 13.9398 5.19799C13.5777 4.32143 12.817 3.04464 11.6796 1.97902C10.5349 0.903571 8.9625 0 6.99879 0ZM3.49909 5.5C3.49909 4.56227 3.86781 3.66295 4.52413 2.99987C5.18045 2.3368 6.07061 1.96429 6.99879 1.96429C7.92696 1.96429 8.81712 2.3368 9.47344 2.99987C10.1298 3.66295 10.4985 4.56227 10.4985 5.5C10.4985 6.43773 10.1298 7.33705 9.47344 8.00013C8.81712 8.6632 7.92696 9.03571 6.99879 9.03571C6.07061 9.03571 5.18045 8.6632 4.52413 8.00013C3.86781 7.33705 3.49909 6.43773 3.49909 5.5ZM6.99879 3.92857C6.99879 4.79531 6.30128 5.5 5.44337 5.5C5.27081 5.5 5.10555 5.47054 4.95001 5.41897C4.81634 5.37478 4.66079 5.45826 4.66565 5.60067C4.67295 5.77009 4.69725 5.93951 4.74343 6.10893C5.07638 7.36607 6.35717 8.1125 7.60151 7.77612C8.84585 7.43973 9.58467 6.14576 9.25172 4.88862C8.98195 3.86964 8.09001 3.1846 7.09843 3.14286C6.95747 3.13795 6.87484 3.29263 6.91858 3.43013C6.96962 3.58728 6.99879 3.75424 6.99879 3.92857Z" fill="black" />
                    </svg>
                  }
                  <InputSwitch
                    className={`shadow-handle !border rounded-full bg-[#E7EAEB] ${!isMasked ? '!border-[#C5C5C5] ' : '!border-primary-500'} p-1`}
                    checked={isMasked}
                    //icon={isMasked ? 'pi pi-eye-slash' : 'pi pi-eye'} 
                    onClick={toggleMask}
                    aria-label="Toggle visibility"
                  />
                </div>
              </div>
              <div>
                {!props.hidden && props.children && props.children}
                {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}
              </div>
            </>
          ) : (inputType === InputType.Masked) ? (
            <>
              {!props.hidden && <InputMask
                id={props.id}
                mask={props.mask}
                slotChar={props.maskChar ? props.maskChar : '_'}
                className={`${className} ${props.readOnly && '!bg-gray-100 !cursor-not-allowed'}`}
                // @ts-ignore: Unreachable code error
                value={props.value ? String(props.value) : ''}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                autoCapitalize={props.autoCapitalize}
                autoComplete="off"
                maxLength={props.maxLength}
                hidden={props.hidden}
                style={props.style}
                data-val-field-label={`${props.id}-feedback`}
                aria-label={props.label}
                readOnly={props.readOnly}
                {...props}
              />}
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}
            </>
          ) : (inputType === InputType.Multiline) ? (
            <>
              {!props.hidden && <InputTextarea
                id={props.id}
                type={inputType}
                className={`${className} ${props.readOnly && '!bg-gray-100 !cursor-not-allowed'}`}
                // @ts-ignore: Unreachable code error
                value={props.value ? String(props.value) : ''}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                autoCapitalize={props.autoCapitalize}
                autoComplete="off"
                maxLength={props.maxLength}
                hidden={props.hidden}
                style={props.style}
                data-val-field-label={`${props.id}-feedback`}
                aria-label={props.label}
                readOnly={props.readOnly}
                {...props}
              />}
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}
            </>
          ) : (
            <>
              {!props.hidden && <InputText
                id={props.id}
                type={inputType}
                className={`${className} ${props.readOnly && '!bg-gray-100 !cursor-not-allowed'}`}
                // @ts-ignore: Unreachable code error
                value={props.value ? String(props.value) : ''}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                autoCapitalize={props.autoCapitalize}
                autoComplete="off"
                maxLength={props.maxLength}
                hidden={props.hidden}
                style={props.style}
                data-val-field-label={`${props.id}-feedback`}
                aria-label={props.label}
                readOnly={props.readOnly}
                {...props}
              />
              }
              {!props.hidden && props.children && props.children}
              {!props.hidden && <InputErrors errors={Appl.Error?.items} viewId={props.id} suffixid={props.suffixid} />}
            </>
          )}
        </>
      )
      }
    </>
  );
});
